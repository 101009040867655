import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import merchantContext from 'context/merchant/MerchantContext';
import { InputField } from 'components/common/InputField';
import CoreLayout from 'components/layouts/CoreLayout';
import { DropdownComponent, PageTitle } from 'components/common';
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
} from '@mui/material';
import { subscriptionRecurrence } from 'constants/merchantConstants';
import { deepCopy } from 'helpers';
import { CreateCategory } from './createCategory';
import { merchant1Categories } from 'api/customersData';

export const CreateSubscription = ({ isEditMode = false }) => {
  const navigate = useNavigate();
  const { subscriptionName, categoryName } = useParams();

  const {
    createMerchantSubscription,
    getMerchantSubscriptions,
    createMerchantCategoryAndSubscription,
    updateMerchantSubscription,
    createMerchantCategoryAndUpdateSubscription,
    state,
  } = useContext(merchantContext);
  const { merchant } = state || {};

  const [showCreateCategory, setShowCreateCategory] = useState(false);
  const [categoryData, setCategoryData] = useState({ categoryLimitations: [] });
  const [shouldCreateCategory, setShouldCreateCategory] = useState(false);

  const [subscriptionData, setSubscriptionData] = useState({
    subscriptionLimitations: [],
    isOnlyOne: false,
  }); // TODO do not have fixed values

  useEffect(() => {
    getMerchantSubscriptions();
  }, []);

  useEffect(() => {
    if (isEditMode) {
      let merchantCategory = merchant1Categories.find(
        (category) => category.categoryName === categoryName
      );

      // merchant?.merchant?.merchantSubscriptions.categories?.find(
      //   (category) => category.categoryName === categoryName
      // );

      if (merchantCategory) {
        merchantCategory = merchantCategory.subscriptions?.find(
          (sub) => sub.subscriptionName.trim() === subscriptionName
        );
        if (merchantCategory) {
          const {
            subscriptionVersion,
            isActiveSubscription,
            _id: id,
            ...subsData
          } = merchantCategory;

          setSubscriptionData({ ...subsData, categoryName });
        }
      }
    }
  }, [isEditMode, merchant?.merchant?.merchantSubscriptions?.categories]);

  const categories = useMemo(() => {
    const names = [];
    merchant?.merchant?.merchantSubscriptions?.categories?.forEach((category) =>
      names.push(category.categoryName)
    );
    return names;
  }, [merchant?.merchant?.merchantSubscriptions]);

  const categoryDropdownValues = useMemo(() => {
    let tmp = deepCopy(categories);
    tmp = tmp.reduce((acc, curr) => {
      acc.push({ key: curr, value: curr });
      return acc;
    }, []);
    tmp.push({ key: 'create', value: 'Create new category' });
    return tmp;
  }, [categories]);

  const saveSubscription = async () => {
    const {
      categoryName: newCategoryName,
      subscriptionName: newSubscriptionName,
      subscriptionMarketingLines,
      ...subscriptionDataToSend
    } = subscriptionData;
    const { categoryName: _, ...categoryDataToSend } = categoryData;
    let marketingLinesNew = subscriptionMarketingLines;
    if (!Array.isArray(subscriptionMarketingLines)) {
      marketingLinesNew = subscriptionMarketingLines
        ?.split('\n')
        .filter((k) => k)
        .map((k) => k.trim());
    }

    if (isEditMode) {
      if (shouldCreateCategory) {
        createMerchantCategoryAndUpdateSubscription(
          newCategoryName,
          categoryName,
          subscriptionName,
          categoryDataToSend,
          {
            ...subscriptionDataToSend,
            subscriptionName: newSubscriptionName,
            subscriptionMarketingLines: marketingLinesNew,
            subscriptionRecurrence:
              subscriptionDataToSend?.subscriptionRecurrence || null,
          }
        );
      } else {
        updateMerchantSubscription(categoryName, subscriptionName, {
          ...subscriptionDataToSend,
          subscriptionName: newSubscriptionName,
          subscriptionMarketingLines: marketingLinesNew,
          subscriptionRecurrence:
            subscriptionDataToSend?.subscriptionRecurrence || null,
        });
      }
      navigate('/merchant-subscriptions');

      return;
    }

    if (shouldCreateCategory) {
      createMerchantCategoryAndSubscription(
        newCategoryName,
        newSubscriptionName,
        categoryDataToSend,
        {
          ...subscriptionDataToSend,
          subscriptionMarketingLines: marketingLinesNew,
          subscriptionRecurrence:
            subscriptionDataToSend?.subscriptionRecurrence || null,
        }
      );
    } else {
      createMerchantSubscription(newCategoryName, newSubscriptionName, {
        ...subscriptionDataToSend,
        subscriptionMarketingLines: marketingLinesNew,
        subscriptionRecurrence:
          subscriptionDataToSend?.subscriptionRecurrence || null,
      });
    }
    navigate('/merchant-subscriptions');
  };

  const createCategoryClicked = async (newCategoryData) => {
    setCategoryData(newCategoryData);
    await categoryDropdownValues.splice(categoryDropdownValues.length - 1, 0, {
      key: newCategoryData.categoryName,
      value: newCategoryData.categoryName,
    });

    setSubscriptionData({
      ...subscriptionData,
      categoryName: newCategoryData?.categoryName,
    });

    setShowCreateCategory(false);
    setShouldCreateCategory(true);
  };

  const onMarketingLineChange = (index, value) => {
    setSubscriptionData({
      ...subscriptionData,
      subscriptionMarketingLines: value,
    });
  };

  return (
    <CoreLayout>
      <PageTitle title={`${isEditMode ? 'Edit' : 'Create'} subscription`} />
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <InputField
            name="subscriptionName"
            placeholder="Enter subscription name"
            label="Subscription Name"
            value={subscriptionData?.subscriptionName || ''}
            fullWidth
            style={{ my: 2 }}
            onChange={(_, value) => {
              setSubscriptionData({
                ...subscriptionData,
                subscriptionName: value,
              });
            }}
          />

          <InputField
            name="subscriptionMarketingTitle"
            placeholder="Enter marketing title"
            label="Marketing Title"
            value={subscriptionData?.subscriptionMarketingTitle || ''}
            fullWidth
            style={{ my: 2 }}
            onChange={(_, value) => {
              setSubscriptionData({
                ...subscriptionData,
                subscriptionMarketingTitle: value,
              });
            }}
          />

          <InputField
            name="subscriptionCost"
            placeholder="Enter subscription cost"
            label="Subscription Cost"
            type="number"
            value={subscriptionData?.subscriptionCost || 0}
            fullWidth
            allowDecimals
            style={{ my: 2 }}
            onChange={(_, value) => {
              setSubscriptionData({
                ...subscriptionData,
                subscriptionCost: parseFloat(value),
              });
            }}
          />

          <InputField
            name="subscriptionPenalty"
            placeholder="Enter subscription penalty"
            label="Subscription Penalty"
            type="number"
            value={subscriptionData?.subscriptionPenalty || 0}
            fullWidth
            style={{ my: 2 }}
            onChange={(_, value) => {
              setSubscriptionData({
                ...subscriptionData,
                subscriptionPenalty: parseFloat(value),
              });
            }}
          />

          <InputField
            name="subscriptionMinimumRecurrence"
            placeholder="Enter minimum recurrence"
            label="Minimum Recurrence"
            type="number"
            value={subscriptionData?.subscriptionMinimumRecurrence || 0}
            fullWidth
            style={{ my: 2 }}
            onChange={(_, value) => {
              setSubscriptionData({
                ...subscriptionData,
                subscriptionMinimumRecurrence: parseInt(value),
              });
            }}
          />
          <DropdownComponent
            name="subscriptionRecurrence"
            label="Subscription Recurrence:"
            options={subscriptionRecurrence}
            isOptionEqualToValue={(option, value) => option.key === value.key}
            defaultValue={
              subscriptionData?.subscriptionRecurrence
                ? {
                    key: subscriptionData.subscriptionRecurrence,
                    value: subscriptionData.subscriptionRecurrence,
                  }
                : null
            }
            onChange={(_, value) => {
              setSubscriptionData({
                ...subscriptionData,
                subscriptionRecurrence: value?.key,
              });
            }}
            style={{ my: 2, width: '100%' }}
          />

          <DropdownComponent
            name="categoryName"
            label="Category Name:"
            disabled={isEditMode}
            options={categoryDropdownValues}
            isOptionEqualToValue={(option, value) => option.key === value.key}
            defaultValue={
              subscriptionData?.categoryName
                ? {
                    key: subscriptionData?.categoryName,
                    value: subscriptionData?.categoryName,
                  }
                : null
            }
            onChange={(_, value) => {
              if (value?.key === 'create') {
                setShowCreateCategory(true);
              } else {
                setShowCreateCategory(false);
                setSubscriptionData({
                  ...subscriptionData,
                  categoryName: value?.key,
                });
              }
            }}
            style={{ my: 2, width: '100%' }}
          />

          <FormGroup>
            <FormControlLabel
              label="Is Auto Renewable"
              control={
                <Checkbox
                  name="isSubscriptionAutoRenewable"
                  checked={
                    subscriptionData?.isSubscriptionAutoRenewable || false
                  }
                  inputProps={{ 'aria-label': 'controlled' }}
                  onChange={(_, value) => {
                    setSubscriptionData({
                      ...subscriptionData,
                      isSubscriptionAutoRenewable: value,
                    });
                  }}
                />
              }
            />
          </FormGroup>
        </Grid>
        <Grid item xs={6}>
          <InputField
            name="subscriptionMarketingLines"
            placeholder="Enter marketing lines"
            label="Marketing Line"
            type="text"
            multiline={true}
            minRows={3}
            maxRows={10}
            value={subscriptionData?.subscriptionMarketingLines || ''}
            fullWidth
            style={{ my: 2 }}
            onChange={(_, value) => {
              onMarketingLineChange(0, value);
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            disabled={showCreateCategory}
            onClick={saveSubscription}
            variant="contained"
            sx={{ ml: 'auto', display: 'block' }}
          >
            Save
          </Button>
        </Grid>
      </Grid>

      {showCreateCategory ? (
        <CreateCategory
          categoryData={categoryData}
          onSave={createCategoryClicked}
        />
      ) : null}
    </CoreLayout>
  );
};
