import React, { useContext, useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, Box, Button } from '@mui/material';
import CoreLayout from '../layouts/CoreLayout';
import MerchantContext from 'context/merchant/MerchantContext';
import {
  PageTitle,
  SubscriptionPackagesGrid,
  Sidebar,
  SimpleAccordion,
} from 'components/common';
import { deepCopy } from 'helpers';
import { Edit } from '@mui/icons-material';
import { merchant1Categories } from 'api/customersData';

export const MerchantServices = () => {
  const navigate = useNavigate();
  const {
    state,
    getMerchantSubscriptions,
    disableMerchantSubscription,
    setSelectedSubscriptionsPerCategory,
    loadSelectedSubscriptionsPerCategory,
    getAllHashes,
  } = useContext(MerchantContext);
  const [subscriptionsForListing, setSubscriptionsForListing] = useState([]);
  const [openedSubscriptionId, setOpenedSubscriptionId] = useState(null);
  const { merchant } = state || {};

  useEffect(() => {
    getMerchantSubscriptions(true);
    loadSelectedSubscriptionsPerCategory();
    getAllHashes();
  }, []);

  const selectedSubscriptions = useMemo(() => {
    return merchant?.merchant?.selectedSubscriptionsPerCategory;
  }, [merchant?.merchant?.selectedSubscriptionsPerCategory]);

  const merchantName = useMemo(() => {
    return merchant?.merchant?.merchantSubscriptions?.merchantName;
  }, [merchant?.merchant?.merchantSubscriptions?.merchantName]);

  const merchantCategories = useMemo(() => {
    return merchant1Categories;
    // return merchant?.merchant?.merchantSubscriptions?.categories;
  }, [merchant?.merchant?.merchantSubscriptions?.categories]);

  const isLoading = useMemo(() => {
    return merchant?.merchant?.loading || false;
  }, [merchant]);

  const pageTitle = useMemo(() => {
    return `Subscriptions ${
      !isLoading && merchantName ? 'for ' + merchantName : ''
    }`;
  }, [merchantName, isLoading]);

  const subscriptionsForSidebar = useMemo(() => {
    let finalData = [];
    merchantCategories?.forEach((category) => {
      // if the category does not have any subscriptions do not display anything in sidebar for that category
      if (!category?.subscriptions?.length) return;

      category?.subscriptions?.forEach((subs) => {
        // add item in the sidebar with data for every active subscription
        finalData.push({
          text: `${subs?.subscriptionName} (${category?.categoryName})`,
          id: subs['_id'],
          subsData: {
            ...subs,
            categoryId: category?.['_id'],
            categoryName: category?.categoryName,
            isActiveCategory: category?.isActiveCategory,
          },
        });
      });
    });

    // return the data that needs to be rendered in the sidebar
    return finalData;

    // the subscriptionsForSidebar value will be reevaluated every time when merchant categories change
  }, [merchantCategories]);

  const handleDelete = async (categoryName, subscription) => {
    // disable subscription when clicking on the delete button
    await disableMerchantSubscription(
      categoryName,
      subscription.subscriptionName
    );
  };

  const onEditClick = (categoryName, subscriptionName) => {
    navigate(
      `/merchant-subscriptions/edit/${categoryName}/${subscriptionName}`
    );
  };

  const getSelectedSubscriptionById = (subsId) => {
    // returns category data with list of subscriptions
    // that has only the one subscription that has same id as the one sent as argument to this function
    const updatedCategory = [];

    // merchant?.merchant?.merchantSubscriptions?.categories?.forEach(
    merchantCategories?.forEach((category) => {
      // go through all categories and find the index of the subscription
      // that has same id as the one sent as argument to this function
      const indexOfSubscriptionInCategory = category.subscriptions.findIndex(
        (subs) => subs['_id'] === subsId
      );
      if (indexOfSubscriptionInCategory !== -1) {
        // this category contains the selected question
        // update category data and its subscriptions list
        const tmp = {
          ...category,
          subscriptions: [
            category.subscriptions[indexOfSubscriptionInCategory],
          ],
        };

        updatedCategory.push(tmp);
      }
    });
    // response is an array which has all data for the category as first element
    // so it can be rendered properly in the grid
    return updatedCategory;
  };

  const handleChosingSubscription = (id) => {
    setOpenedSubscriptionId(id);

    // handles selecting item in the sidebar
    if (!id) {
      // if the id is not valid render all categories with subscriptions (render initial data)
      setSubscriptionsForListing([]);
      return;
    }

    // if the id is valid then get chosen subscription data
    // update list of subscriptions that needs to be shown
    // and the section displaying the subscriptions will be rerendered
    const chosenSubscriptionData = getSelectedSubscriptionById(id);
    setSubscriptionsForListing(chosenSubscriptionData);
  };

  const onSelectSubscriptionInSidebar = (selectedSubscription) => {
    const categoryName = selectedSubscription?.subsData?.categoryName;
    if (!categoryName) return;

    if (selectedSubscriptions[categoryName]) {
      if (
        selectedSubscriptions[categoryName].find(
          (sub) => sub.id === selectedSubscription?.id
        )
      ) {
        const filteredSubscriptions = selectedSubscriptions[
          categoryName
        ].filter((subs) => subs.id !== selectedSubscription.id);
        let newSubs = deepCopy(selectedSubscriptions);

        if (filteredSubscriptions.length) {
          newSubs = {
            ...newSubs,
            [categoryName]: filteredSubscriptions,
          };
        } else {
          delete newSubs[categoryName];
        }
        setSelectedSubscriptionsPerCategory(newSubs);
      } else {
        setSelectedSubscriptionsPerCategory({
          ...selectedSubscriptions,
          [categoryName]: [
            ...selectedSubscriptions[categoryName],
            selectedSubscription,
          ],
        });
      }
    } else {
      setSelectedSubscriptionsPerCategory({
        ...selectedSubscriptions,
        [categoryName]: [selectedSubscription],
      });
    }
  };

  // This function is called when a item is selected from the array of items
  const handleItemSelected = (item) => {
    const itemId = item.id || item['_id'];
    if (itemId === openedSubscriptionId) {
      // if the user clicks on already selected item, then the item is unselected
      setOpenedSubscriptionId(null);
      handleChosingSubscription(null);
      return;
    }
    // set the selected item id in state
    setOpenedSubscriptionId(itemId);
    // on select of item propagate to parent the id of the item
    handleChosingSubscription(itemId);
  };

  return (
    <CoreLayout>
      <PageTitle title={pageTitle} />
      {isLoading ? (
        <p>Loading...</p>
      ) : subscriptionsForSidebar?.length ? (
        <Box sx={{ width: '100%', display: 'flex' }}>
          <Sidebar
            data={subscriptionsForSidebar}
            filterPlaceholder="Filter subscriptions and categories"
            onSelectItem={handleChosingSubscription}
            handleItemSelected={handleItemSelected}
            hasCheckboxes={true}
            selectedSubscriptions={selectedSubscriptions}
            onSelectSubscription={onSelectSubscriptionInSidebar}
            openedSubscriptionId={openedSubscriptionId}
          />
          <Grid container sx={{ width: 'calc(100vw - 360px)' }}>
            <Grid item xs={7} sx={{ pl: '30px' }}>
              {subscriptionsForListing
                ? subscriptionsForListing.map((subscriptionsCategory, i) => (
                    <SubscriptionPackagesGrid
                      key={i}
                      category={subscriptionsCategory}
                      isMerchant={true}
                      onClick={handleDelete}
                      onEditClick={onEditClick}
                      categoryActionIconComponent={<Edit />}
                      onCategoryActionClick={() =>
                        navigate(
                          `/merchant-categories/edit/${subscriptionsCategory?.categoryName}`
                        )
                      }
                    />
                  ))
                : null}
            </Grid>
            <Grid item xs={5}>
              {Object.keys(selectedSubscriptions)?.length ? (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  <Button
                    variant="contained"
                    onClick={() =>
                      navigate('/generateLink', {
                        state: { selectedSubscriptions: selectedSubscriptions },
                      })
                    }
                  >
                    Generate Link
                  </Button>
                  <Button
                    onClick={() => setSelectedSubscriptionsPerCategory({})}
                  >
                    Unselect all
                  </Button>
                </Box>
              ) : (
                <>
                  {merchant?.merchant?.hashes?.length && (
                    <h3>Existing hashes: </h3>
                  )}
                  <ul>
                    {merchant?.merchant?.hashes?.map((hashData) => (
                      <li>{hashData['_id']}</li>
                    ))}
                  </ul>
                </>
              )}
              <SimpleAccordion
                data={selectedSubscriptions}
                handleItemSelected={handleItemSelected}
              />
            </Grid>
          </Grid>
        </Box>
      ) : (
        <p>There are no active subscriptions.</p>
      )}
    </CoreLayout>
  );
};
