import { CLOSE_TOAST_MESSAGE, SHOW_TOAST_MESSAGE } from './types';

export default (state, action) => {
  switch (action.type) {
    case SHOW_TOAST_MESSAGE:
      const { severity, message } = action.payload;

      return {
        ...state,
        toastMessage: { ...state?.toastMessage, message, severity },
      };
    case CLOSE_TOAST_MESSAGE:
      return {
        ...state,
        toastMessage: { ...state?.toastMessage, message: '', severity: '' },
      };
  }
};
