// Variables for the Reducer

export const SET_ELEMENT_VALUE = 'CUSTOMER_SET_ELEMENT_VALUE';

export const LIST_CUSTOMER_SUBSCRIPTIONS = 'LIST_CUSTOMER_SUBSCRIPTIONS';

export const GET_CUSTOMER_SUBSCRIPTIONS = 'CUSTOMER_GET_SUBSCRIPTIONS';
export const GET_CUSTOMER_SUBSCRIPTIONS_LOADING =
  'CUSTOMER_GET_SUBSCRIPTIONS_LOADING';

export const LIST_CUSTOMER_USAGE_DATA = 'LIST_CUSTOMER_USAGE_DATA';
export const GET_CUSTOMER_USAGE_DATA_LOADING =
  'GET_CUSTOMER_USAGE_DATA_LOADING';

export const GET_CUSTOMER_SUBSCRIPTIONS_FOR_MERCHANT =
  'GET_CUSTOMER_SUBSCRIPTIONS_FOR_MERCHANT';
export const GET_CUSTOMER_SUBSCRIPTIONS_FOR_MERCHANT_LOADING =
  'GET_CUSTOMER_SUBSCRIPTIONS_FOR_MERCHANT_LOADING';

export const GET_MERCHANTS_FOR_CUSTOMER_LOADING =
  'GET_MERCHANTS_FOR_CUSTOMER_LOADING';

export const GET_MERCHANTS_FOR_CUSTOMER = 'GET_MERCHANTS_FOR_CUSTOMER';

export const REMOVE_CUSTOMER_SUBSCRIPTION_LOADING =
  'REMOVE_CUSTOMER_SUBSCRIPTION_LOADING';
