/* This is a temporary file in which mock data is stored,
  before connecting the services to the API from backend */

export const usageReports = [
  {
    transactionDate: new Date().getDate(),
    transactionTime: new Date().getTime(),
    transactionID: '1',
    transactionDetails: 'details 1',
    transactionAmount: 11.99,
    transactionCurrency: 'eur',
  },
  {
    transactionDate: new Date().getDate(),
    transactionTime: new Date().getTime(),
    transactionID: '2',
    transactionDetails: 'details 1',
    transactionAmount: 12.99,
    transactionCurrency: 'eur',
  },
  {
    transactionDate: new Date().getDate(),
    transactionTime: new Date().getTime(),
    transactionID: '3',
    transactionDetails: 'details 3',
    transactionAmount: 13.99,
    transactionCurrency: 'eur',
  },
  {
    transactionDate: new Date().getDate(),
    transactionTime: new Date().getTime(),
    transactionID: '4',
    transactionDetails: 'details 4',
    transactionAmount: 14.99,
    transactionCurrency: 'eur',
  },
  {
    transactionDate: new Date().getDate(),
    transactionTime: new Date().getTime(),
    transactionID: '5',
    transactionDetails: 'details 5',
    transactionAmount: 15.99,
    transactionCurrency: 'eur',
  },
  {
    transactionDate: new Date().getDate(),
    transactionTime: new Date().getTime(),
    transactionID: '6',
    transactionDetails: 'details 6',
    transactionAmount: 16.99,
    transactionCurrency: 'eur',
  },
];

const category1Subscriptions = [
  {
    subscriptionName: 'Golden',
    _id: 1,
    subscriptionVersion: 1,
    subscriptionExpirationDate: new Date(),
    isActiveSubscription: true,
    subscriptionMarketingTitle: 'Our best plan promise',
    subscriptionMarketingLines: ['line 1.1', 'line 1.2'],
    subscriptionCost: 12.99,
    subscriptionRecurrance: 'weekly',
    isSubscriptionAutoRenewable: false,
    subscriptionPenalty: 0.0,
    subscriptionMinimumRecurrence: 0,
  },
  {
    subscriptionName: 'Subscription 2',
    _id: 2,
    subscriptionVersion: 1,
    subscriptionExpirationDate: new Date(),
    isActiveSubscription: true,
    subscriptionMarketingTitle: 'title',
    subscriptionMarketingLines: ['line 2.1', 'line 2.2'],
    subscriptionCost: 9.99,
    subscriptionRecurrance: 'daily',
    isSubscriptionAutoRenewable: false,
    subscriptionPenalty: 0.0,
    subscriptionMinimumRecurrence: 0,
  },
];

const category2Subscriptions = [
  {
    subscriptionName: 'Subs 1',
    _id: 5,
    subscriptionVersion: 1,
    subscriptionExpirationDate: new Date(),
    isActiveSubscription: true,
    subscriptionMarketingTitle: 'subs1 title',
    subscriptionMarketingLines: ['subs line 1.1', 'subs line 1.2'],
    subscriptionCost: 24.49,
    subscriptionRecurrance: 'biweekly',
    isSubscriptionAutoRenewable: false,
    subscriptionPenalty: 0.0,
    subscriptionMinimumRecurrence: 0,
  },
  {
    subscriptionName: 'Subs 2',
    _id: 6,
    subscriptionVersion: 1,
    subscriptionExpirationDate: new Date(),
    isActiveSubscription: true,
    subscriptionMarketingTitle: 'subs2 title',
    subscriptionMarketingLines: ['subs line 2.1', 'subs line 2.2'],
    subscriptionCost: 11.49,
    subscriptionRecurrance: 'monthly',
    isSubscriptionAutoRenewable: false,
    subscriptionPenalty: 0.0,
    subscriptionMinimumRecurrence: 0,
  },
  {
    subscriptionName: 'Subs 3',
    _id: 7,
    subscriptionVersion: 1,
    subscriptionExpirationDate: new Date(),
    isActiveSubscription: false,
    subscriptionMarketingTitle: 'subs3 2 title',
    subscriptionMarketingLines: ['subs line 3.1', 'subs line 3.2'],
    subscriptionCost: 0.99,
    subscriptionRecurrance: false,
    isSubscriptionAutoRenewable: false,
    subscriptionPenalty: 0.0,
    subscriptionMinimumRecurrence: 0,
  },
];

export const merchant1Categories = [
  {
    subscriptions: category1Subscriptions,
    categoryName: 'Category 1',
    _id: 1,
    categoryImageURL:
      'https://us.123rf.com/450wm/conceptw/conceptw1904/conceptw190400028/123561302-beautiful-colored-glass-letter-m-glowing-in-the-dark.jpg?ver=6',
    categoryVersion: 1,
    isActiveCategory: false,
  },
  {
    subscriptions: category2Subscriptions,
    categoryName: 'Category 2',
    _id: 2,
    categoryImageURL:
      'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQympOhvL1TVNj7iCdQTyKCo5roWblAC5ygLQ&usqp=CAU',
    categoryVersion: 1,
    isActiveCategory: true,
  },
];

const merchant2Categories = [
  {
    subscriptions: category1Subscriptions,
    categoryName: 'Category 1',
    _id: 9,
    categoryImageURL:
      'https://us.123rf.com/450wm/conceptw/conceptw1904/conceptw190400028/123561302-beautiful-colored-glass-letter-m-glowing-in-the-dark.jpg?ver=6',
    categoryVersion: 1,
    isActiveCategory: true,
  },
];

const merchant1 = {
  merchantName: 'Merchant 1',
  merchantID: '1',
  merchantAddressLine1: 'line 1',
  merchantAddressLine2: 'line 2',
  merchantAddressLine3: 'line 3',
  merchantAddressLine4: 'line 4',
  merchantAddressLine5: 'line 5',
  merchantPostCode: 'postCode',
  merchantCountry: 'country',
  merchantMasterEmail: 'email',
  merchantSalesEmail: 'sales email',
  merchantCustomerHelperEmail: 'helper email',
  merchantCategories: merchant1Categories,
};

const merchant2 = {
  merchantName: 'Merchant 2',
  merchantID: '2',
  merchantAddressLine1: 'line 1',
  merchantAddressLine2: 'line 2',
  merchantAddressLine3: 'line 3',
  merchantAddressLine4: 'line 4',
  merchantAddressLine5: 'line 5',
  merchantPostCode: 'postCode',
  merchantCountry: 'country',
  merchantMasterEmail: 'email',
  merchantSalesEmail: 'sales email',
  merchantCustomerHelperEmail: 'helper email',
  merchantCategories: merchant2Categories,
};

const merchant3 = {
  merchantName: 'Merchant 3',
  merchantID: '3',
  merchantAddressLine1: 'line 1',
  merchantAddressLine2: 'line 2',
  merchantAddressLine3: 'line 3',
  merchantAddressLine4: 'line 4',
  merchantAddressLine5: 'line 5',
  merchantPostCode: 'postCode',
  merchantCountry: 'country',
  merchantMasterEmail: 'email',
  merchantSalesEmail: 'sales email',
  merchantCustomerHelperEmail: 'helper email',
  merchantCategories: merchant1Categories,
};

export const customerMerchants = [merchant1, merchant2, merchant3];
