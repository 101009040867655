import React, { useContext, useEffect, useState, useMemo } from 'react';
import { Grid } from '@mui/material';
import CoreLayout from '../layouts/CoreLayout';
import CustomerContext from '../../context/customer/CustomerContext';
import { PageTitle, SubscriptionPackagesGrid } from 'components/common';
import CustomTabs from 'components/common/Tabs';
import UserContext from 'context/user/UserContext';

export const CustomerDashboard = () => {
  const { user, getUserInfo } = useContext(UserContext);
  const { state, listCustomerSubscriptions, removeCustomerSubscription } =
    useContext(CustomerContext);

  const { customer } = state || {};

  const [customerData, setCustomerData] = useState(
    customer?.customer?.customerMerchants || {}
  );

  useEffect(() => {
    listCustomerSubscriptions();
    getUserInfo();
  }, []);

  useEffect(() => {
    setCustomerData(customer?.customer?.customerMerchants);
  }, [customer]);

  const toggleSubscription = async (merchant, categoryName, subscription) => {
    removeCustomerSubscription(subscription?.subscriptionName, {
      paymentMethod: 'stripe',
      merchantMasterEmail: merchant?.merchantMasterEmail,
      categoryName,
    });
  };

  const tabsAndPanels = useMemo(() => {
    let newTabs = [];
    let newTabPanels = [];

    customerData?.forEach((merchant, index) => {
      newTabs.push(
        <CustomTabs.CustomTab
          label={merchant?.merchantName}
          id={`tab-${index}`}
          aria-controls={`tabpanel-${index}`}
        />
      );

      newTabPanels.push(
        <CustomTabs.CustomTabPanel>
          {
            // TODO implement loading
            // merchant?.loading ? (<CircularProgress sx={{ mx: 'auto', display: 'block' }} /> ) :
            merchant?.categories?.length ? (
              merchant.categories.map((category, j) => (
                <SubscriptionPackagesGrid
                  key={j}
                  category={category}
                  onClick={(categoryName, subscription) =>
                    toggleSubscription(merchant, categoryName, subscription)
                  }
                />
              ))
            ) : (
              <small>no results</small>
            )
          }
        </CustomTabs.CustomTabPanel>
      );
    });

    return { tabs: newTabs, tabPanels: newTabPanels };
  }, [customerData]);

  return (
    <CoreLayout>
      <PageTitle title={`${user?.name}'s Current Subscriptions`} />

      <Grid container columnSpacing={7} sx={{ width: '100%', marginLeft: '0' }}>
        <CustomTabs
          activeTab={`merchant name${0}`}
          tabsAndPanels={tabsAndPanels}
        />
      </Grid>
    </CoreLayout>
  );
};
