import { useReducer } from 'react';
import MerchantContext from './MerchantContext';
import merchantReducer from './MerchantReducer';
import * as merchantService from '../../api/merchant';
import {
  SET_ELEMENT_VALUE,
  GET_MERCHANT_DASHBOARD_DATA_LOADING,
  GET_MERCHANT_DASHBOARD_DATA,
  GET_MERCHANT_SUBSCRIPTIONS_LOADING,
  GET_MERCHANT_SUBSCRIPTIONS_SUCCESS,
  GET_SINGLE_MERCHANT_SUBSCRIPTION_LOADING,
  GET_SINGLE_MERCHANT_SUBSCRIPTION_SUCCESS,
  DISABLE_MERCHANT_SUBSCRIPTION_LOADING,
  CREATE_MERCHANT_CATEGORY_LOADING,
  DELETE_MERCHANT_CATEGORY_LOADING,
  CREATE_MERCHANT_SUBSCRIPTION_LOADING,
  UPDATE_MERCHANT_SUBSCRIPTION_LOADING,
  SET_MERCHANT_SELECTED_SUBSCRIPTIONS,
  UPDATE_MERCHANT_CATEGORY_LOADING,
  GET_HASH_LOADING,
  GET_HASH_SUCCESS,
  GET_HASHES_LOADING,
  CREATE_HASH_LOADING,
  GET_ALL_HASHES_SUCCESS,
  CREATE_HASH_SUCCESS,
} from './types';
import { loadFromLocalStorage, storeInLocalStorage } from 'helpers';

export const initialState = {
  merchant: {
    merchant: {
      merchantItems: [],
      merchantSubscriptions: [],
      loading: false,
      selectedSubscriptionsPerCategory: {},
      selectedHash: '',
      createdHash: '',
      hashes: [],
    },
  },
};

const MerchantState = (props) => {
  // state allows us to access anything in the state and dispatch allows dispatching objects to the reducer
  // populate the merchantReducer with the initial state to instantiate it
  const [state, dispatch] = useReducer(merchantReducer, initialState);

  const setMerchantDashboardData = (dashboardData) => {
    // dispatch action to the reducer and update the state accordingly
    dispatch({
      type: GET_MERCHANT_DASHBOARD_DATA,
      payload: dashboardData,
    });
  };

  const loadSelectedSubscriptionsPerCategory = () => {
    const selectedSubs = loadFromLocalStorage('selectedSubscriptions') || {};

    dispatch({
      type: SET_MERCHANT_SELECTED_SUBSCRIPTIONS,
      payload: selectedSubs,
    });
  };

  const setSelectedSubscriptionsPerCategory = (newData) => {
    dispatch({
      type: SET_MERCHANT_SELECTED_SUBSCRIPTIONS,
      payload: newData,
    });

    storeInLocalStorage('selectedSubscriptions', newData); //remove on logout
  };

  const listMerchantDashboardData = () => {
    // dispatch action to the reducer and update the state accordingly
    dispatch({
      type: GET_MERCHANT_DASHBOARD_DATA_LOADING,
      payload: true,
    });

    // get merchant's dashboard data
    merchantService
      .getMerchantDashboardData()
      .then((result) => setMerchantDashboardData(result))
      .catch((error) => console.error(error));
  };

  const getMerchantSubscriptions = (statusOfSubscription) => {
    dispatch({
      type: GET_MERCHANT_SUBSCRIPTIONS_LOADING,
      payload: true,
    });

    merchantService
      .getMerchantSubscriptions(statusOfSubscription)
      .then((result) => {
        dispatch({
          type: GET_MERCHANT_SUBSCRIPTIONS_LOADING,
          payload: false,
        });
        dispatch({
          type: GET_MERCHANT_SUBSCRIPTIONS_SUCCESS,
          payload: result,
        });
      })
      .catch((error) => console.error(error));
  };

  const getSingleMerchantSubscription = (subscriptionName, categoryName) => {
    dispatch({
      type: GET_SINGLE_MERCHANT_SUBSCRIPTION_LOADING,
      payload: true,
    });

    merchantService
      .getOneMerchantSubscription(subscriptionName, categoryName)
      .then((result) => {
        dispatch({
          type: GET_SINGLE_MERCHANT_SUBSCRIPTION_SUCCESS,
          payload: result,
        });
      })
      .catch((error) => console.error(error));
  };

  const disableMerchantSubscription = (categoryName, subscription) => {
    dispatch({
      type: DISABLE_MERCHANT_SUBSCRIPTION_LOADING,
      payload: true,
    });

    merchantService
      .disableMerchantSubscription(categoryName, subscription)
      .then(() => {
        dispatch({
          type: DISABLE_MERCHANT_SUBSCRIPTION_LOADING,
          payload: false,
        });
        getMerchantSubscriptions(true);
      })
      .catch((error) => console.error(error));
  };

  const createMerchantSubscription = (categoryName, subscriptionName, data) => {
    dispatch({
      type: CREATE_MERCHANT_SUBSCRIPTION_LOADING,
      payload: true,
    });

    merchantService
      .createSubscription(categoryName, subscriptionName, data)
      .then((result) => {
        dispatch({
          type: CREATE_MERCHANT_SUBSCRIPTION_LOADING,
          payload: false,
        });
        dispatch({
          type: GET_MERCHANT_SUBSCRIPTIONS_SUCCESS,
          payload: result,
        });
      })
      .catch((error) => console.error(error));
  };

  const updateMerchantSubscription = (categoryName, subscriptionName, data) => {
    dispatch({
      type: UPDATE_MERCHANT_SUBSCRIPTION_LOADING,
      payload: true,
    });

    merchantService
      .updateSubscription(categoryName, subscriptionName, data)
      .then((result) => {
        dispatch({
          type: UPDATE_MERCHANT_SUBSCRIPTION_LOADING,
          payload: false,
        });
        dispatch({
          type: GET_MERCHANT_SUBSCRIPTIONS_SUCCESS,
          payload: result,
        });
      })
      .catch((error) => console.error(error));
  };

  const createMerchantCategory = (categoryName, data) => {
    dispatch({
      type: CREATE_MERCHANT_CATEGORY_LOADING,
      payload: true,
    });

    merchantService
      .createCategory(categoryName, data)
      .then(() => {
        dispatch({
          type: CREATE_MERCHANT_CATEGORY_LOADING,
          payload: false,
        });
      })
      .catch((error) => console.error(error));
  };

  const updateMerchantCategory = (categoryName, data) => {
    dispatch({
      type: UPDATE_MERCHANT_CATEGORY_LOADING,
      payload: true,
    });
    merchantService
      .updateCategory(categoryName, data)
      .then((result) => {
        dispatch({
          type: UPDATE_MERCHANT_CATEGORY_LOADING,
          payload: false,
        });
        dispatch({
          type: GET_MERCHANT_SUBSCRIPTIONS_SUCCESS,
          payload: result,
        });
      })
      .catch((error) => console.error(error));
  };

  const createMerchantCategoryAndSubscription = (
    categoryName,
    subscriptionName,
    categoryDataToSend,
    subscriptionDataToSend
  ) => {
    dispatch({
      type: CREATE_MERCHANT_CATEGORY_LOADING,
      payload: true,
    });

    merchantService
      .createCategory(categoryName, categoryDataToSend)
      .then((result) => {
        dispatch({
          type: CREATE_MERCHANT_CATEGORY_LOADING,
          payload: false,
        });
        createMerchantSubscription(
          categoryName,
          subscriptionName,
          subscriptionDataToSend
        );
        dispatch({
          type: GET_MERCHANT_SUBSCRIPTIONS_SUCCESS,
          payload: result,
        });
      })
      .catch((error) => console.error(error));
  };

  const createMerchantCategoryAndUpdateSubscription = (
    newCategoryName,
    categoryName,
    subscriptionName,
    categoryDataToSend,
    subscriptionDataToSend
  ) => {
    dispatch({
      type: CREATE_MERCHANT_CATEGORY_LOADING,
      payload: true,
    });

    merchantService
      .createCategory(newCategoryName, categoryDataToSend)
      .then((result) => {
        dispatch({
          type: CREATE_MERCHANT_CATEGORY_LOADING,
          payload: false,
        });
        updateMerchantSubscription(
          categoryName,
          subscriptionName,
          subscriptionDataToSend
        );
        dispatch({
          type: GET_MERCHANT_SUBSCRIPTIONS_SUCCESS,
          payload: result,
        });
      })
      .catch((error) => console.error(error));
  };

  const deleteMerchantCategory = (categoryName) => {
    dispatch({
      type: DELETE_MERCHANT_CATEGORY_LOADING,
      payload: true,
    });

    merchantService
      .deleteCategory(categoryName)
      .then(() => {
        dispatch({
          type: DELETE_MERCHANT_CATEGORY_LOADING,
          payload: false,
        });
        getMerchantSubscriptions();
      })
      .catch((error) => console.error(error));
  };

  const createHash = (data) => {
    dispatch({
      type: CREATE_HASH_LOADING,
      payload: true,
    });

    merchantService
      .createHash(data)
      .then((result) => {
        dispatch({
          type: CREATE_HASH_LOADING,
          payload: false,
        });
        dispatch({
          type: CREATE_HASH_SUCCESS,
          payload: result,
        });
        console.log('result');
        return result;
      })
      .catch((error) => {
        console.error(error);
        return;
      });
  };

  const getAllHashes = () => {
    dispatch({
      type: GET_HASHES_LOADING,
      payload: true,
    });

    merchantService
      .getAllHashes()
      .then((result) => {
        dispatch({
          type: GET_HASHES_LOADING,
          payload: false,
        });
        dispatch({
          type: GET_ALL_HASHES_SUCCESS,
          payload: result, //TODO improve
        });
      })
      .catch((error) => console.error(error));
  };

  const getHashById = (id) => {
    dispatch({
      type: GET_HASH_LOADING,
      payload: true,
    });

    merchantService
      .getHash(id)
      .then((result) => {
        dispatch({
          type: GET_HASH_LOADING,
          payload: false,
        });
        dispatch({
          type: GET_HASH_SUCCESS,
          payload: result,
        });
      })
      .catch((error) => console.error(error));
  };

  const setElementValue = (name, value) => {
    // dispatch action to the reducer and update the state accordingly
    dispatch({
      type: SET_ELEMENT_VALUE,
      payload: {
        name,
        value,
      },
    });
  };

  return (
    <MerchantContext.Provider
      value={{
        state: state || {},
        setElementValue,
        listMerchantDashboardData,
        getMerchantSubscriptions,
        getSingleMerchantSubscription,
        disableMerchantSubscription,
        createMerchantCategory,
        updateMerchantCategory,
        deleteMerchantCategory,
        createMerchantSubscription,
        updateMerchantSubscription,
        createMerchantCategoryAndSubscription,
        createMerchantCategoryAndUpdateSubscription,
        setSelectedSubscriptionsPerCategory,
        loadSelectedSubscriptionsPerCategory,
        getAllHashes,
        createHash,
        getHashById,
      }}
    >
      {props.children}
    </MerchantContext.Provider>
  );
};

export default MerchantState;
