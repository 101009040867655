export const CURRENCY_LIST = [
  {
    key: 'DEFAULT',
    value: 'Please Select',
  },
  {
    key: 'AFA',
    value: 'AFA - Afghan Afghani',
  },
  {
    key: 'ALL',
    value: 'ALL - Albanian Lek',
  },
  {
    key: 'DZD',
    value: 'DZD - Algerian Dinar',
  },
  {
    key: 'AOA',
    value: 'AOA - Angolan Kwanza',
  },
  {
    key: 'ARS',
    value: 'ARS - Argentine Peso',
  },
  {
    key: 'AMD',
    value: 'AMD - Armenian Dram',
  },
  {
    key: 'AWG',
    value: 'AWG - Aruban Florin',
  },
  {
    key: 'AUD',
    value: 'AUD - Australian Dollar',
  },
  {
    key: 'AZN',
    value: 'AZN - Azerbaijani Manat',
  },
  {
    key: 'BSD',
    value: 'BSD - Bahamian Dollar',
  },
  {
    key: 'BHD',
    value: 'BHD - Bahraini Dinar',
  },
  {
    key: 'BDT',
    value: 'BDT - Bangladeshi Taka',
  },
  {
    key: 'BBD',
    value: 'BBD - Barbadian Dollar',
  },
  {
    key: 'BYR',
    value: 'BYR - Belarusian Ruble',
  },
  {
    key: 'BEF',
    value: 'BEF - Belgian Franc',
  },
  {
    key: 'BZD',
    value: 'BZD - Belize Dollar',
  },
  {
    key: 'BMD',
    value: 'BMD - Bermudan Dollar',
  },
  {
    key: 'BTN',
    value: 'BTN - Bhutanese Ngultrum',
  },
  {
    key: 'BTC',
    value: 'BTC - Bitcoin',
  },
  {
    key: 'BOB',
    value: 'BOB - Bolivian Boliviano',
  },
  {
    key: 'BAM',
    value: 'BAM - Bosnia-Herzegovina Convertible Mark',
  },
  {
    key: 'BWP',
    value: 'BWP - Botswanan Pula',
  },
  {
    key: 'BRL',
    value: 'BRL - Brazilian Real',
  },
  {
    key: 'GBP',
    value: 'GBP - British Pound Sterling',
  },
  {
    key: 'BND',
    value: 'BND - Brunei Dollar',
  },
  {
    key: 'BGN',
    value: 'BGN - Bulgarian Lev',
  },
  {
    key: 'BIF',
    value: 'BIF - Burundian Franc',
  },
  {
    key: 'KHR',
    value: 'KHR - Cambodian Riel',
  },
  {
    key: 'CAD',
    value: 'CAD - Canadian Dollar',
  },
  {
    key: 'CVE',
    value: 'CVE - Cape Verdean Escudo',
  },
  {
    key: 'KYD',
    value: 'KYD - Cayman Islands Dollar',
  },
  {
    key: 'XOF',
    value: 'XOF - CFA Franc BCEAO',
  },
  {
    key: 'XAF',
    value: 'XAF - CFA Franc BEAC',
  },
  {
    key: 'XPF',
    value: 'XPF - CFP Franc',
  },
  {
    key: 'CLP',
    value: 'CLP - Chilean Peso',
  },
  {
    key: 'CNY',
    value: 'CNY - Chinese Yuan',
  },
  {
    key: 'COP',
    value: 'COP - Colombian Peso',
  },
  {
    key: 'KMF',
    value: 'KMF - Comorian Franc',
  },
  {
    key: 'CDF',
    value: 'CDF - Congolese Franc',
  },
  {
    key: 'CRC',
    value: 'CRC - Costa Rican ColÃ³n',
  },
  {
    key: 'HRK',
    value: 'HRK - Croatian Kuna',
  },
  {
    key: 'CUC',
    value: 'CUC - Cuban Convertible Peso',
  },
  {
    key: 'CZK',
    value: 'CZK - Czech Republic Koruna',
  },
  {
    key: 'DKK',
    value: 'DKK - Danish Krone',
  },
  {
    key: 'DJF',
    value: 'DJF - Djiboutian Franc',
  },
  {
    key: 'DOP',
    value: 'DOP - Dominican Peso',
  },
  {
    key: 'XCD',
    value: 'XCD - East Caribbean Dollar',
  },
  {
    key: 'EGP',
    value: 'EGP - Egyptian Pound',
  },
  {
    key: 'ERN',
    value: 'ERN - Eritrean Nakfa',
  },
  {
    key: 'EEK',
    value: 'EEK - Estonian Kroon',
  },
  {
    key: 'ETB',
    value: 'ETB - Ethiopian Birr',
  },
  {
    key: 'EUR',
    value: 'EUR - Euro',
  },
  {
    key: 'FKP',
    value: 'FKP - Falkland Islands Pound',
  },
  {
    key: 'FJD',
    value: 'FJD - Fijian Dollar',
  },
  {
    key: 'GMD',
    value: 'GMD - Gambian Dalasi',
  },
  {
    key: 'GEL',
    value: 'GEL - Georgian Lari',
  },
  {
    key: 'DEM',
    value: 'DEM - German Mark',
  },
  {
    key: 'GHS',
    value: 'GHS - Ghanaian Cedi',
  },
  {
    key: 'GIP',
    value: 'GIP - Gibraltar Pound',
  },
  {
    key: 'GRD',
    value: 'GRD - Greek Drachma',
  },
  {
    key: 'GTQ',
    value: 'GTQ - Guatemalan Quetzal',
  },
  {
    key: 'GNF',
    value: 'GNF - Guinean Franc',
  },
  {
    key: 'GYD',
    value: 'GYD - Guyanaese Dollar',
  },
  {
    key: 'HTG',
    value: 'HTG - Haitian Gourde',
  },
  {
    key: 'HNL',
    value: 'HNL - Honduran Lempira',
  },
  {
    key: 'HKD',
    value: 'HKD - Hong Kong Dollar',
  },
  {
    key: 'HUF',
    value: 'HUF - Hungarian Forint',
  },
  {
    key: 'ISK',
    value: 'ISK - Icelandic KrÃ³na',
  },
  {
    key: 'INR',
    value: 'INR - Indian Rupee',
  },
  {
    key: 'IDR',
    value: 'IDR - Indonesian Rupiah',
  },
  {
    key: 'IRR',
    value: 'IRR - Iranian Rial',
  },
  {
    key: 'IQD',
    value: 'IQD - Iraqi Dinar',
  },
  {
    key: 'ILS',
    value: 'ILS - Israeli New Sheqel',
  },
  {
    key: 'ITL',
    value: 'ITL - Italian Lira',
  },
  {
    key: 'JMD',
    value: 'JMD - Jamaican Dollar',
  },
  {
    key: 'JPY',
    value: 'JPY - Japanese Yen',
  },
  {
    key: 'JOD',
    value: 'JOD - Jordanian Dinar',
  },
  {
    key: 'KZT',
    value: 'KZT - Kazakhstani Tenge',
  },
  {
    key: 'KES',
    value: 'KES - Kenyan Shilling',
  },
  {
    key: 'KWD',
    value: 'KWD - Kuwaiti Dinar',
  },
  {
    key: 'KGS',
    value: 'KGS - Kyrgystani Som',
  },
  {
    key: 'LAK',
    value: 'LAK - Laotian Kip',
  },
  {
    key: 'LVL',
    value: 'LVL - Latvian Lats',
  },
  {
    key: 'LBP',
    value: 'LBP - Lebanese Pound',
  },
  {
    key: 'LSL',
    value: 'LSL - Lesotho Loti',
  },
  {
    key: 'LRD',
    value: 'LRD - Liberian Dollar',
  },
  {
    key: 'LYD',
    value: 'LYD - Libyan Dinar',
  },
  {
    key: 'LTL',
    value: 'LTL - Lithuanian Litas',
  },
  {
    key: 'MOP',
    value: 'MOP - Macanese Pataca',
  },
  {
    key: 'MKD',
    value: 'MKD - Macedonian Denar',
  },
  {
    key: 'MGA',
    value: 'MGA - Malagasy Ariary',
  },
  {
    key: 'MWK',
    value: 'MWK - Malawian Kwacha',
  },
  {
    key: 'MYR',
    value: 'MYR - Malaysian Ringgit',
  },
  {
    key: 'MVR',
    value: 'MVR - Maldivian Rufiyaa',
  },
  {
    key: 'MRO',
    value: 'MRO - Mauritanian Ouguiya',
  },
  {
    key: 'MUR',
    value: 'MUR - Mauritian Rupee',
  },
  {
    key: 'MXN',
    value: 'MXN - Mexican Peso',
  },
  {
    key: 'MDL',
    value: 'MDL - Moldovan Leu',
  },
  {
    key: 'MNT',
    value: 'MNT - Mongolian Tugrik',
  },
  {
    key: 'MAD',
    value: 'MAD - Moroccan Dirham',
  },
  {
    key: 'MZM',
    value: 'MZM - Mozambican Metical',
  },
  {
    key: 'MMK',
    value: 'MMK - Myanmar Kyat',
  },
  {
    key: 'NAD',
    value: 'NAD - Namibian Dollar',
  },
  {
    key: 'NPR',
    value: 'NPR - Nepalese Rupee',
  },
  {
    key: 'ANG',
    value: 'ANG - Netherlands Antillean Guilder',
  },
  {
    key: 'TWD',
    value: 'TWD - New Taiwan Dollar',
  },
  {
    key: 'NZD',
    value: 'NZD - New Zealand Dollar',
  },
  {
    key: 'NIO',
    value: 'NIO - Nicaraguan CÃ³rdoba',
  },
  {
    key: 'NGN',
    value: 'NGN - Nigerian Naira',
  },
  {
    key: 'KPW',
    value: 'KPW - North Korean Won',
  },
  {
    key: 'NOK',
    value: 'NOK - Norwegian Krone',
  },
  {
    key: 'OMR',
    value: 'OMR - Omani Rial',
  },
  {
    key: 'PKR',
    value: 'PKR - Pakistani Rupee',
  },
  {
    key: 'PAB',
    value: 'PAB - Panamanian Balboa',
  },
  {
    key: 'PGK',
    value: 'PGK - Papua New Guinean Kina',
  },
  {
    key: 'PYG',
    value: 'PYG - Paraguayan Guarani',
  },
  {
    key: 'PEN',
    value: 'PEN - Peruvian Nuevo Sol',
  },
  {
    key: 'PHP',
    value: 'PHP - Philippine Peso',
  },
  {
    key: 'PLN',
    value: 'PLN - Polish Zloty',
  },
  {
    key: 'QAR',
    value: 'QAR - Qatari Rial',
  },
  {
    key: 'RON',
    value: 'RON - Romanian Leu',
  },
  {
    key: 'RUB',
    value: 'RUB - Russian Ruble',
  },
  {
    key: 'RWF',
    value: 'RWF - Rwandan Franc',
  },
  {
    key: 'SVC',
    value: 'SVC - Salvadoran ColÃ³n',
  },
  {
    key: 'WST',
    value: 'WST - Samoan Tala',
  },
  {
    key: 'SAR',
    value: 'SAR - Saudi Riyal',
  },
  {
    key: 'RSD',
    value: 'RSD - Serbian Dinar',
  },
  {
    key: 'SCR',
    value: 'SCR - Seychellois Rupee',
  },
  {
    key: 'SLL',
    value: 'SLL - Sierra Leonean Leone',
  },
  {
    key: 'SGD',
    value: 'SGD - Singapore Dollar',
  },
  {
    key: 'SKK',
    value: 'SKK - Slovak Koruna',
  },
  {
    key: 'SBD',
    value: 'SBD - Solomon Islands Dollar',
  },
  {
    key: 'SOS',
    value: 'SOS - Somali Shilling',
  },
  {
    key: 'ZAR',
    value: 'ZAR - South African Rand',
  },
  {
    key: 'KRW',
    value: 'KRW - South Korean Won',
  },
  {
    key: 'XDR',
    value: 'XDR - Special Drawing Rights',
  },
  {
    key: 'LKR',
    value: 'LKR - Sri Lankan Rupee',
  },
  {
    key: 'SHP',
    value: 'SHP - St. Helena Pound',
  },
  {
    key: 'SDG',
    value: 'SDG - Sudanese Pound',
  },
  {
    key: 'SRD',
    value: 'SRD - Surinamese Dollar',
  },
  {
    key: 'SZL',
    value: 'SZL - Swazi Lilangeni',
  },
  {
    key: 'SEK',
    value: 'SEK - Swedish Krona',
  },
  {
    key: 'CHF',
    value: 'CHF - Swiss Franc',
  },
  {
    key: 'SYP',
    value: 'SYP - Syrian Pound',
  },
  {
    key: 'STD',
    value: 'STD - São Tomé and Príncipe Dobra',
  },
  {
    key: 'TJS',
    value: 'TJS - Tajikistani Somoni',
  },
  {
    key: 'TZS',
    value: 'TZS - Tanzanian Shilling',
  },
  {
    key: 'THB',
    value: 'THB - Thai Baht',
  },
  {
    key: 'TOP',
    value: "TOP - Tongan Pa'anga",
  },
  {
    key: 'TTD',
    value: 'TTD - Trinidad & Tobago Dollar',
  },
  {
    key: 'TND',
    value: 'TND - Tunisian Dinar',
  },
  {
    key: 'TRY',
    value: 'TRY - Turkish Lira',
  },
  {
    key: 'TMT',
    value: 'TMT - Turkmenistani Manat',
  },
  {
    key: 'UGX',
    value: 'UGX - Ugandan Shilling',
  },
  {
    key: 'UAH',
    value: 'UAH - Ukrainian Hryvnia',
  },
  {
    key: 'AED',
    value: 'AED - United Arab Emirates Dirham',
  },
  {
    key: 'UYU',
    value: 'UYU - Uruguayan Peso',
  },
  {
    key: 'USD',
    value: 'USD - US Dollar',
  },
  {
    key: 'UZS',
    value: 'UZS - Uzbekistan Som',
  },
  {
    key: 'VUV',
    value: 'VUV - Vanuatu Vatu',
  },
  {
    key: 'VEF',
    value: 'VEF - Venezuelan BolÃvar',
  },
  {
    key: 'VND',
    value: 'VND - Vietnamese Dong',
  },
  {
    key: 'YER',
    value: 'YER - Yemeni Rial',
  },
  {
    key: 'ZMK',
    value: 'ZMK - Zambian Kwacha',
  },
];
