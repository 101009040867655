import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
  Navigate,
} from 'react-router-dom';
import { Box } from '@mui/material';
import ThemeState from 'context/theme/ThemeState';
import UserState from 'context/user/UserState';

import Login from 'components/pages/login';
import { Logout, NotFound404 } from 'components/common';

import './themes/default/pages/home.scss';
import './themes/default/common.scss';
import './themes/default/elements.scss';
import './themes/default/nav.scss';
import './themes/default/sections.scss';
import './themes/dark/pages/home.scss';
import './themes/dark/elements-dark.scss';
import './themes/dark/sections-dark.scss';
import './themes/dark/nav-dark.scss';
import './themes/blue/pages/home.scss';
import './themes/blue/elements-blue.scss';
import './themes/blue/sections-blue.scss';
import './themes/blue/nav-blue.scss';

import Wrapper from 'components/layouts/Wrapper';
import { Merchant } from 'components/pages/merchant';
import { MerchantDashboard } from 'components/pages/merchantDashboard';
import { MerchantServices } from 'components/pages/merchantServices';
import { MerchantArchived } from 'components/pages/merchantArchived';
import { MerchantWallet } from 'components/pages/merchantWallet';
import { CustomerWallet } from 'components/pages/customerWallet';
import MerchantState from 'context/merchant/MerchantState';
import ToastMessageState from 'context/toastMessage/ToastMessageState';

import { CustomerDashboard } from 'components/pages/customerDashboard';
import CustomerState from 'context/customer/CustomerState';
import { CreateSubscription } from 'components/pages/createSubscription';
import { GenerateLink } from 'components/pages/generateLink';
import { ChooseSubscription } from 'components/pages/chooseSubscription';
import { CreateCategory } from 'components/pages/createCategory';

const App = () => {
  return (
    <Router>
      <Box className="App">
        <ThemeState>
          <UserState>
            <MerchantState>
              <CustomerState>
                <ToastMessageState>
                  <Wrapper>
                    <Routes>
                      <Route
                        exact
                        path="/"
                        element={<Navigate to="/login" />}
                      />
                      <Route exact path="/merchant" element={<Merchant />} />
                      <Route
                        exact
                        path="/merchant-dashboard"
                        element={<MerchantDashboard />}
                      />
                      <Route
                        exact
                        path="/merchant-subscriptions"
                        element={<MerchantServices />}
                      />
                      <Route
                        exact
                        path="/merchant-categories/edit/:categoryName"
                        element={<CreateCategory isEditMode={true} />}
                      />
                      <Route
                        exact
                        path="/merchant-archived"
                        element={<MerchantArchived />}
                      />
                      <Route
                        exact
                        path="/merchant-subscriptions/create"
                        element={<CreateSubscription />}
                      />
                      <Route
                        exact
                        path="/merchant-subscriptions/edit/:categoryName/:subscriptionName"
                        element={<CreateSubscription isEditMode={true} />}
                      />
                      <Route
                        exact
                        path="/merchant-wallet"
                        element={<MerchantWallet />}
                      />
                      <Route
                        path="/customer-dashboard"
                        element={<CustomerDashboard />}
                      />
                      <Route
                        path="/chooseSubscription/:hashId"
                        element={<ChooseSubscription />}
                      />
                      <Route path="/generateLink" element={<GenerateLink />} />
                      <Route
                        path="/customer-wallet"
                        element={<CustomerWallet />}
                      />
                      <Route exact path="/login" element={<Login />} />
                      <Route exact path="/logout" element={<Logout />} />
                      <Route path="*" element={<NotFound404 />} />
                    </Routes>
                  </Wrapper>
                </ToastMessageState>
              </CustomerState>
            </MerchantState>
          </UserState>
        </ThemeState>
      </Box>
    </Router>
  );
};

export default App;
