import { useContext } from 'react';
import { Box, Card, CardContent, Typography, Grid } from '@mui/material';
import ThemeContext from 'context/theme/ThemeContext';

export const SimpleCard = ({
  title,
  subtitle,
  xs = 12,
  sm = 12,
  md = 6,
  lg = 3,
}) => {
  const { withTheme } = useContext(ThemeContext);

  return (
    <Grid
      item
      xs={xs}
      sm={sm}
      md={md}
      lg={lg}
      sx={{ flexGrow: 1, display: 'flex', alignSelf: 'stretch' }}
    >
      <Card sx={{ display: 'flex' }} className={`${withTheme('simple-card')}`}>
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          <CardContent sx={{ flex: '1 0 auto' }} className={`card-content`}>
            <Typography component="div" variant="h5" sx={{ fontSize: '1rem' }}>
              {title}
            </Typography>
            <Typography
              variant="subtitle1"
              color="text.secondary"
              component="div"
              sx={{ fontSize: '0.7rem' }}
            >
              {subtitle}
            </Typography>
          </CardContent>
        </Box>
      </Card>
    </Grid>
  );
};
