import React from 'react';
import { Typography } from '@mui/material';

export const PageTitle = ({ title, variant = 'h4', sx = {} }) => {
  return (
    <Typography
      variant={variant}
      sx={{ textAlign: 'center', mx: 'auto', my: 5, ...sx }}
    >
      {title}
    </Typography>
  );
};
