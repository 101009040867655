import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export const SimpleAccordion = ({ data = {}, handleItemSelected = null }) => {
  return (
    <>
      {Object.entries(data)?.map(([title, item], index) => (
        <Accordion key={index} defaultExpanded={true}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${index}a-content`}
            id={`panel${index}a-header`}
          >
            <Typography sx={{ fontWeight: 700 }}>{title}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {item?.map((selectedItem, indexS) => (
              <Typography
                key={indexS}
                sx={
                  handleItemSelected
                    ? {
                        '&:hover': {
                          textDecoration: 'underline',
                          cursor: 'pointer',
                        },
                      }
                    : {}
                }
                onClick={() =>
                  handleItemSelected
                    ? handleItemSelected(selectedItem?.subsData)
                    : () => {}
                }
              >
                {selectedItem?.subsData?.subscriptionName}
              </Typography>
            ))}
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
};
