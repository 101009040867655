import React, { useState, useEffect } from 'react';
import { Button, Checkbox } from '@mui/material';
import { Box } from '@mui/system';
import { InputField } from './InputField';
import { deepCopy } from 'helpers';

export const Sidebar = ({
  data = [],
  onSelectItem = () => {},
  filterPlaceholder = '',
  openedSubscriptionId = null,
  hasCheckboxes = false,
  handleItemSelected = null,
  selectedSubscriptions = [],
  onSelectSubscription = () => {},
}) => {
  // filer for items in sidebar
  const [itemsFilter, setSubscriptionsFilter] = useState('');
  // filtered items in sidebar
  const [filteredItemsData, setFilteredItemsData] = useState(deepCopy(data));

  useEffect(() => {
    // when the data passed to sidebar from parent component is changed, update the filtered items in sidebar
    setFilteredItemsData(deepCopy(data));
  }, [data]);

  // on click of clear selection button, reset filter and close selected item in sidebar
  const handleClearSelectionClicked = () => {
    onSelectItem(null);
    setSubscriptionsFilter('');
    setFilteredItemsData(data);
  };

  // on selecting item from the array of items in sidebar
  const onItemSelected = (item) => {
    if (item.id === openedSubscriptionId) {
      // if the user clicks on already selected item, then the item is unselected
      onSelectItem(null);
      return;
    }
    // on select of item propagate to parent the id of the item
    onSelectItem(item.id);
  };

  // on change of filter input field, update items in sidebar
  const handleChange = (_, value) => {
    setSubscriptionsFilter(value);
    if (!value) {
      // if the filter input field is empty then show all initial data
      setFilteredItemsData(data);
      return;
    }

    // else filter the data by the entered value
    const filteredData = data.filter((item) =>
      item.text.toLowerCase().includes(value.toLowerCase())
    );

    // update the data that should be rendered in the sidebar items
    setFilteredItemsData(filteredData);
  };

  const getContentForSidebarItem = (item) => {
    const { subsData } = item;

    if (!subsData) return null;

    return (
      <>
        v{subsData?.subscriptionVersion} - {subsData?.subscriptionCost}&#x20AC;
        {subsData?.subscriptionRecurrence && '/month'} - exp{' '}
        {subsData?.subscriptionExpirationDate}
      </>
    );
  };

  return (
    <Box
      sx={{
        borderRight: 'solid 2px #00000054',
        padding: '20px',
        maxHeight: '80vh',
        overflow: 'scroll',
        minHeight: '80vh',
        padding: '20px',
        width: '360px',
        display: { xs: 'none', md: 'block' },
      }}
    >
      <Button onClick={handleClearSelectionClicked}>x clear selection</Button>
      <InputField
        name="filter"
        placeholder={filterPlaceholder}
        value={itemsFilter}
        clearable={true}
        fullWidth={true}
        onChange={handleChange}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}
      >
        {filteredItemsData?.map((item, index) => {
          return (
            <Box sx={{ display: 'flex', flexDirection: 'row' }} key={index}>
              {hasCheckboxes ? (
                <Box sx={{ my: 'auto', ml: 0, pl: 0, width: '42px' }}>
                  <Checkbox
                    checked={
                      selectedSubscriptions[item.subsData?.categoryName]
                        ? selectedSubscriptions[
                            item.subsData?.categoryName
                          ]?.some((subs) => subs.id === item.id)
                        : false
                    }
                    onChange={() => onSelectSubscription(item)}
                  />
                </Box>
              ) : null}
              <Box
                sx={{
                  width: hasCheckboxes ? 'calc(100% - 42px)' : '100%',
                }}
              >
                <Box
                  sx={{
                    height: '80px',
                    background: 'whitesmoke',
                    display: 'flex',
                    alignItems: 'center',
                    padding: '15px',
                    '&:hover': {
                      cursor: 'pointer',
                    },
                    border:
                      openedSubscriptionId === item.id
                        ? '2px solid blue'
                        : '1px solid gray',
                    borderRadius: '5px',
                  }}
                  onClick={() =>
                    handleItemSelected
                      ? handleItemSelected(item)
                      : onItemSelected(item)
                  }
                >
                  <Box
                    sx={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      width: '100%',
                      textAlign: 'center',
                    }}
                  >
                    #{data.length - index} {item?.text}
                    <b
                      style={{
                        display: 'block',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {item?.subsData.categoryName}
                    </b>
                    <small
                      style={{
                        display: 'block',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      <i>{getContentForSidebarItem(item)}</i>
                    </small>
                  </Box>
                </Box>
              </Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};
