export const getUserGroup = (userData) => {
  if (!userData) {
    return 'notAuthorised';
  }
  return userData['cognito:groups'] && userData['cognito:groups'].length
    ? userData['cognito:groups'][0]
    : 'noUserGroup';
};

export const getQuestionnaireUniqueId = (creationTime, userId) => {
  if (!creationTime) return null;

  let hash1 = null;
  if (userId) {
    hash1 = userId.toString().slice(-5);
  }

  return `${hash1}${creationTime}`;
};

export const formatDateFromMiliseconds = (miliseconds) => {
  const date = new Date(parseInt(miliseconds, 10));
  const month = date.getMonth() + 1;
  const day = date.getDate();

  return `${day < 10 ? '0' : ''}${day}/${
    month < 10 ? '0' : ''
  }${month}/${date.getFullYear()}`;
};

export const formatTimeFromMiliseconds = (miliseconds) => {
  const date = new Date(parseInt(miliseconds, 10));
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();

  return `${hours < 10 ? '0' : ''}${hours}:${
    minutes < 10 ? '0' : ''
  }${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
};

export const deepCopy = (element) => JSON.parse(JSON.stringify(element));

export const storeInLocalStorage = (name, data) => {
  localStorage.setItem(name, JSON.stringify(data));
};

export const loadFromLocalStorage = (name) => {
  try {
    const localStorageData = localStorage.getItem(name);
    return JSON.parse(localStorageData);
  } catch (error) {
    console.log('No data found in local storage for: ', name);
    return '';
  }
};
