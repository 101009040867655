export const frequencyOptions = [
  { key: 'DEFAULT', value: 'Frequency' },
  { key: 'day', value: 'Per Day' },
  { key: 'week', value: 'Per Week' },
  { key: '2weeks', value: 'Per 2 Weeks' },
  { key: 'month', value: 'Per Month' },
  { key: '2months', value: 'Per 2 Months' },
  { key: '3months', value: 'Per 3 Months' },
  { key: '6months', value: 'Per 6 Months' },
  { key: 'year', value: 'Per Year' },
  { key: '5years', value: 'Per 5 Years' },
];

export const subscriptionRecurrence = [
  { key: 'day', value: 'day' },
  { key: 'week', value: 'week' },
  { key: 'month', value: 'month' },
  { key: 'year', value: 'year' },
];

export const merchantMetrics = [
  {
    label: 'Unique Customer Login',
    color: '#7D1E6A',
    contentKey: 'noOfUniqueCustomerLoginInPeriod',
  },
  {
    label: 'No of Subscriptions Purchased',
    color: '#EEB0B0',
    contentKey: 'noOfInvoicesInPeriod',
  },
  {
    label: 'Total Money Received',
    color: '#BDE6F1',
    contentKey: 'totalMoneyInWalletinPeriod',
  },
];
