import React, { useReducer, useRef } from 'react';
import { isExpired, decodeToken } from 'react-jwt';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  CognitoUserAttribute,
  CognitoUserPool,
  CognitoUser,
} from 'amazon-cognito-identity-js';

import UserContext from './UserContext';
import UserReducer from './UserReducer';
import config from '../../config';

import { SET_USER_INFO, SET_USER_GROUP } from './types';
import { storeInLocalStorage } from 'helpers';

// define the initial state of our store (context)
const initialState = {
  user: null,
  userGroup: null,
};

// get the needed attributes so we can connect to AWS Cognito
const {
  cognitoAuthUrl,
  cognitoClientId,
  redirect_uri,
  cognitoClientSecret,
  userPoolId,
} = config;

// Create poolData struct that holds the ClientId and UserPoolId
const poolData = {
  ClientId: cognitoClientId,
  UserPoolId: userPoolId,
};

// Set up an AWS (amazon-cognito-identity-js) CognitoUserPool object using the previously defined pool data
const userPool = new CognitoUserPool(poolData);

const UserState = (props) => {
  const [state, dispatch] = useReducer(UserReducer, initialState);
  // We need to define and initialize a navigation method which will be called every time that we need to redirect the user to some page
  const navigate = useNavigate();
  // We need to define and initialize a location property that will be used every time that we want to get the current url and/or path property.
  const location = useLocation();
  const isInterceptorLoaded = useRef();

  // Register a CognitoUserAttribute
  // This is part of the default code
  const register = (name, email, password) => {
    return new Promise((resolve, reject) => {
      const emailAttribute = new CognitoUserAttribute({
        Name: 'email',
        Value: email.toLowerCase(),
      });

      const nameAttribute = new CognitoUserAttribute({
        Name: 'name',
        Value: name.toLowerCase(),
      });

      userPool.signUp(
        email,
        password,
        [emailAttribute, nameAttribute],
        [],
        async (err, result) => {
          if (err) {
            console.log(err);
            reject(err);
          } else if (result) {
            resolve(result);
          }
        }
      );
    });
  };

  const resendConfirmation = (email) => {
    const userData = {
      Username: email,
      Pool: userPool,
    };

    const cognitoUser = new CognitoUser(userData);
    cognitoUser.resendConfirmationCode(function (err, result) {
      if (err) {
        alert(err.message || JSON.stringify(err));
        return;
      }
    });
  };

  // Called when we get back from Cognito after the user has logged in
  // We can get the bearer token fro the URL and then exchange it to OpenID
  // Connect credentials i.e. access-token and refresh-token (like in JWT)
  const getCredentialsFromCode = async (code) => {
    try {
      const result = await axios.post(
        cognitoAuthUrl + '/oauth2/token',
        new URLSearchParams({
          grant_type: 'authorization_code',
          redirect_uri,
          client_id: cognitoClientId,
          scope: 'email openid profile',
          code,
        }),
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: `Basic ${btoa(
              cognitoClientId + ':' + cognitoClientSecret
            )}`,
          },
        }
      );
      const { id_token, access_token, refresh_token } = result.data;

      // Store the id_token, access_token and refresh_token temporarelly in local storage. We will need to remove them when the user logs out.
      const promise1 = localStorage.setItem('id_token', id_token);
      const promise2 = localStorage.setItem('access_token', access_token);
      const promise3 = localStorage.setItem('refresh_token', refresh_token);

      Promise.allSettled([promise1, promise2, promise3]).then(() => {
        getUserInfo();
      });
      // TODO: check if this setter is necessary
      setUserGroup();
    } catch (error) {
      // If some error occurs than print the response to the console
      console.log(error.response);
    }
  };

  // We use this method to get the user info.
  const isAuthenticatedUser = () => {
    const idToken = localStorage.getItem('id_token');
    const accessToken = localStorage.getItem('access_token');
    // If there is no id_token and/or 'access_token' in local storage then that means that the user is not logged in properly and the user is navigated to the /login page
    if (!idToken || !accessToken) {
      return false;
    }

    // If those two values are successfully extracted then the access token needs to be checked if it is expired. If it is expired, then the refreshTokens() method is called so we can get new access and id tokens
    if (isExpired(accessToken)) {
      refreshTokens(true);
    }
    // If the access token is valid and not expired then we are returning information that the user is signed in
    return true;
  };

  const getUserInfo = () => {
    try {
      const idToken = localStorage.getItem('id_token');
      const decodedToken = decodeToken(idToken);

      if (isAuthenticatedUser()) {
        // If we get information that the user is logged in, then we are dispatching an action to the reducer with the SET_USER_INFO type and passing the decoded token as new value for the state
        dispatch({
          type: SET_USER_INFO,
          payload: decodedToken,
        });
      } else {
        if (location && location.pathname !== '/login') {
          storeInLocalStorage(
            'redirect_url',
            location.pathname + location.search + location.hash
          );
          navigate('/login');
        }
      }
    } catch (error) {
      // If some error occurs while decoding the id token then we are printing that error to the console
      console.log(error);
    }
  };

  const setUserGroup = (userGroup) => {
    dispatch({ type: SET_USER_GROUP, payload: userGroup });
  };

  // This method is called when the access token or id token is not valid or expired and we need to use the refresh token (previously stored into the local storage) so we can get new values for the id and access tokens for the current user.
  const refreshTokens = (redirect) => {
    return new Promise(async (resolve, reject) => {
      const refreshToken = localStorage.getItem('refresh_token');
      if (!refreshToken || decodeToken(refreshToken)) {
        // If there is no refresh token stored in the localStorage then we are dispatching an action to the reducer with the SET_USER_INFO type and passing null as value for new state of our context because there is no valid access token for the user.
        // After that, we are redirecting (navigating) the user to the /login page so he can log in and get new id and access tokens
        dispatch({
          type: SET_USER_INFO,
          payload: null,
        });
        signOut();
      }

      // If the refresh token exists in local storage and we have sucessfully extracted it, then make the next call and get the newly generated id and access tokens from its response
      try {
        const result = await axios.post(
          cognitoAuthUrl + '/oauth2/token',
          new URLSearchParams({
            grant_type: 'refresh_token',
            client_id: cognitoClientId,
            client_secret: cognitoClientSecret,
            refresh_token: refreshToken,
          }),
          {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          }
        );
        const { id_token, access_token } = result.data;
        localStorage.setItem('id_token', id_token);
        localStorage.setItem('access_token', access_token);

        if (redirect) {
          getUserInfo();
          navigate(location.pathname);
        } else {
          // window.location.reload();
        }
        resolve(access_token);
      } catch (e) {
        // If some error occurs while decoding the access token then we are printing that error to the console
        // After that we are dispatching an action to the reducer with the SET_USER_INFO type and passing null as value for new state of our context because there is no valid access token for the user.
        // After that, we are redirecting (navigating) the user to the /login page so he can log in and get new id and access tokens
        console.log(e.response);
        dispatch({
          type: SET_USER_INFO,
          payload: null,
        });
        signOut();
      }
    });
  };

  // Used in signout code
  const signOut = async () => {
    try {
      const idToken = localStorage.getItem('id_token');
      if (idToken) {
        const { email } = decodeToken(idToken);

        const userData = {
          Username: email,
          Pool: userPool,
        };

        const cognitoUser = new CognitoUser(userData);
        cognitoUser.signOut();
      }

      localStorage.removeItem('id_token');
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
      localStorage.removeItem('selectedSubscriptions');
      localStorage.removeItem('redirect_url');
      navigate('/login');
    } catch (error) {
      console.log(error);
    }

    dispatch({
      type: SET_USER_INFO,
      payload: null,
    });

    return true;
  };

  if (!isInterceptorLoaded.current) {
    isInterceptorLoaded.current = true;
    // Automatically process the API request with a new access token if the old one is expired
    axios.interceptors.request.use(async (config) => {
      const accessToken = localStorage.getItem('access_token');

      if (
        (isExpired(accessToken) || !config.headers.Authorization) &&
        !config.url.includes(cognitoAuthUrl)
      ) {
        const newAccessToken = await refreshTokens();

        config.headers = {
          ...config.headers,
          Authorization: `Bearer ${newAccessToken}`,
        };

        return config;
      }

      return config;
    });
  }

  return (
    <UserContext.Provider
      value={{
        ...state,
        userState: state || {},
        register,
        getUserInfo,
        setUserGroup,
        refreshTokens,
        resendConfirmation,
        isAuthenticatedUser,
        getCredentialsFromCode,
        signOut,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};

export default UserState;
