import React, { useReducer } from 'react';
import ToastMessageContext from './ToastMessageContext';
import ToastMessageReducer from './ToastMessageReducer';
import { SHOW_TOAST_MESSAGE, CLOSE_TOAST_MESSAGE } from './types';

export const initialState = {
  toastMessage: {
    message: '',
    severity: 'success',
  },
};

const ToastMessageState = (props) => {
  const [state, dispatch] = useReducer(ToastMessageReducer, initialState);

  const showToastMessage = (toastMessageData) => {
    // dispatch action to the reducer and update the state accordingly
    dispatch({
      type: SHOW_TOAST_MESSAGE,
      payload: toastMessageData,
    });
  };

  const closeToastMessage = () => {
    dispatch({
      type: CLOSE_TOAST_MESSAGE,
      payload: { message: '', severity: '' },
    });
  };

  return (
    <ToastMessageContext.Provider
      value={{
        state: state || {},
        showToastMessage,
        closeToastMessage,
      }}
    >
      {props.children}
    </ToastMessageContext.Provider>
  );
};

export default ToastMessageState;
