import React, { useContext, useState, useEffect } from 'react';
import { Grid, Box, Button } from '@mui/material';
import CoreLayout from '../layouts/CoreLayout';
import MerchantContext from 'context/merchant/MerchantContext';
import UserContext from 'context/user/UserContext';
import ThemeContext from 'context/theme/ThemeContext';
import { frequencyOptions, merchantMetrics } from 'constants/merchantConstants';
import {
  PageTitle,
  HorizontalSMCards,
  DropdownComponent,
  FieldsetBox,
} from 'components/common';

export const MerchantDashboard = () => {
  const { state, listMerchantDashboardData } = useContext(MerchantContext);
  const { withTheme } = useContext(ThemeContext);
  const { merchant } = state || {};

  const [merchantData, setMerchantData] = useState(
    merchant?.merchant?.merchantDashboardData || {}
  );

  const [frequency, setFrequency] = useState('');
  const [showReport, setShowReport] = useState(false);

  useEffect(() => {
    listMerchantDashboardData();
  }, []);

  useEffect(() => {
    setMerchantData(merchant?.merchant?.merchantDashboardData);
  }, [merchant]);

  useEffect(() => {
    if (showReport) {
      // @TODO call generate report endpoint
    }
  }, [showReport]);

  const handleFrequencyChange = (name, value) => {
    // @TODO we should call setElementValue from context and update "frequency" in global merchant state on change in dropdown
    setFrequency(value?.key || '');
  };

  return (
    <CoreLayout>
      <PageTitle title={'Merchant Dashboard Customer Connectivity'} />

      <Grid container>
        <Box sx={{ width: '100%' }}>
          {merchantData ? <HorizontalSMCards dataMe={merchantData} /> : null}
        </Box>

        {!showReport ? (
          <Button
            variant="contained"
            onClick={() => setShowReport(true)}
            sx={{ mt: 4 }}
            className={withTheme('btn-secondary')}
          >
            Generate Report
          </Button>
        ) : (
          <>
            <Box sx={{ width: '100%' }}>
              <DropdownComponent
                name="frequency"
                label="Display data:"
                options={frequencyOptions}
                onChange={handleFrequencyChange}
                style={{ ml: 'auto', my: 3 }}
              />
            </Box>

            <Box sx={{ width: '100%' }}>
              <Grid container columnSpacing={5}>
                {merchantMetrics.map((metric, index) => {
                  return (
                    <FieldsetBox
                      key={index}
                      label={metric.label}
                      color={metric.color}
                      content={merchantData && merchantData[metric.contentKey]}
                    />
                  );
                })}
              </Grid>
            </Box>
          </>
        )}
      </Grid>
    </CoreLayout>
  );
};
