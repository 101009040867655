import React, { useContext } from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import ThemeContext from 'context/theme/ThemeContext';

export const ThemeSwitcherSelect = ({ themeOptions }) => {
  const { theme, setTheme } = useContext(ThemeContext);

  const changeTheme = (event) => {
    if (theme.name) {
      setTheme(event.target.value);
    } else {
      setTheme('default');
    }
  };

  return (
    <FormControl
      variant="standard"
      fullWidth
      style={{
        width: '130px',
        marginRight: '30px',
      }}
    >
      <InputLabel id="select-theme-label">Theme</InputLabel>
      <Select
        labelId="select-theme-label"
        id="select-theme"
        value={theme.name}
        label={theme.name}
        onChange={changeTheme}
        size="small"
      >
        {themeOptions.map(({ value, name }, i) => (
          <MenuItem key={i} value={value}>
            {name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
