import { Grid } from '@mui/material';
import { SimpleCard } from 'components/common';

//? TODO improve "data" array

export const HorizontalSMCards = ({ dataMe = {} }) => {
  if (!dataMe) {
    return null;
  }

  const data = [
    { title: dataMe.merchantName, subtitle: 'Merchant Name' },
    {
      title: dataMe.merchantAddressLine1,
      subtitle: 'Merchant Address Line',
    },
    {
      title: dataMe.merchantSalesEmail,
      subtitle: 'Merchant Sales Email',
    },
    {
      title: dataMe.merchantCustomerHelperEmail,
      subtitle: 'Merchant Customer Helper Email',
    },
  ];

  return (
    <Grid container spacing={3} alignItems="stretch">
      {data.map(({ title, subtitle }, i) => (
        <SimpleCard title={title} subtitle={subtitle} key={i} />
      ))}
    </Grid>
  );
};
