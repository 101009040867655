import React from 'react';
import { Grid } from '@mui/material';
import CoreLayout from '../layouts/CoreLayout';
import { PageTitle } from 'components/common';

export const Merchant = () => {
  return (
    <CoreLayout>
      <Grid item sx={{ width: '100%' }}>
        <Grid item sx={{ marginLeft: 8, marginRight: 8 }}>
          <PageTitle element={{ title: 'test custom page' }} />
        </Grid>
      </Grid>
    </CoreLayout>
  );
};
