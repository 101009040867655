import React, { useState, useEffect, useContext } from 'react';
import { Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import CoreLayout from 'components/layouts/CoreLayout';
import UserContext from 'context/user/UserContext';
import customerContext from 'context/customer/CustomerContext';
import { PageTitle, SubscriptionPackagesGrid } from 'components/common';
import { getHash } from 'api/customer';

// TODO: improve

export const ChooseSubscription = () => {
  const { hashId } = useParams();

  const { getUserInfo } = useContext(UserContext);
  const { state, listMerchantsForCustomer } = useContext(customerContext);
  const { customer } = state;
  const [data, setData] = useState({});

  useEffect(() => {
    listMerchantsForCustomer();
  }, []);

  useEffect(() => {
    const getData = async () => {
      const response = await getHash(hashId);
      setData({ categories: response?.data?.categories || [] });
    };
    getData();
  }, [hashId]);

  useEffect(() => {
    getUserInfo();
  }, []);

  return (
    <CoreLayout>
      <PageTitle
        title={`Available Subscriptions for ${customer?.customer?.allMerchants}`}
      />

      {data?.categories ? (
        <Grid
          container
          columnSpacing={7}
          sx={{ width: '100%', marginLeft: '0' }}
        >
          {data?.categories?.map((category, j) => (
            <SubscriptionPackagesGrid
              key={j}
              category={category}
              // check which are already active?? dont show subscribe for them
              onClick={
                (categoryName, subscription) => {
                  //
                }
                // toggleSubscription(merchant, categoryName, subscription)
              }
            />
          ))}
        </Grid>
      ) : (
        <small>no results</small>
      )}
    </CoreLayout>
  );
};
