import { Grid } from '@mui/material';
import React from 'react';

/* This is custom component that wraps the fieldset HTML element.
  It accepts sizes, label, color, content and height of the box as props. 
  Only the label of the fieldset and the content are required props,
  because they are necessary so the fieldset coould make sense.
  The label is the title of the fieldset, and the content is the content inside the fieldset.
*/

export const FieldsetBox = (props) => {
  const {
    sizes = { xs: 12, md: 6, lg: 4 },
    label,
    color = 'grey',
    content = 'dummy',
    height = '300px',
  } = props;

  return (
    <Grid item {...sizes} sx={{ marginBottom: '40px' }}>
      <Grid
        item
        sx={{
          maxWidth: { xs: '300px', lg: '350px' },
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      >
        <fieldset>
          <legend style={{ fontSize: '16px' }}>{label}</legend>
          <Grid
            item
            sx={{
              color,
              height,
              textAlign: 'center',
              paddingBottom: '20px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '60px',
              fontWeight: '700',
            }}
          >
            {content}
            {props.children}
          </Grid>
        </fieldset>
      </Grid>
    </Grid>
  );
};
