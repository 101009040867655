// Redux for moving attributes around with state
import {
  SET_ELEMENT_VALUE,
  GET_MERCHANT_DASHBOARD_DATA,
  GET_MERCHANT_SUBSCRIPTIONS_LOADING,
  GET_MERCHANT_SUBSCRIPTIONS_SUCCESS,
  DISABLE_MERCHANT_SUBSCRIPTION_LOADING,
  SET_MERCHANT_SELECTED_SUBSCRIPTIONS,
  GET_HASH_SUCCESS,
  GET_ALL_HASHES_SUCCESS,
  CREATE_HASH_SUCCESS,
} from './types';

/* 
  This is the Merchant's Context reducer. It accepts action type (string) 
  and updates the store's state accordingly
*/

export default (state, action) => {
  switch (action.type) {
    // map all action types to corresponding action (change of state)

    case SET_ELEMENT_VALUE: {
      const { name, value } = action.payload;
      return {
        ...state,
        merchant: {
          ...state.merchant,
          [name]: value,
        },
      };
    }

    case GET_MERCHANT_DASHBOARD_DATA:
      return {
        ...state,
        merchant: {
          ...state.merchant,
          merchant: {
            ...state.merchant.merchant,
            merchantDashboardData: action.payload,
          },
        },
      };

    case GET_MERCHANT_SUBSCRIPTIONS_LOADING:
      return {
        ...state,
        merchant: {
          ...state.merchant,
          merchant: {
            ...state.merchant.merchant,
            loading: true,
          },
        },
      };

    case GET_MERCHANT_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        merchant: {
          ...state.merchant,
          merchant: {
            ...state.merchant.merchant,
            merchantSubscriptions: action.payload,
            loading: false,
          },
        },
      };

    case SET_MERCHANT_SELECTED_SUBSCRIPTIONS:
      return {
        ...state,
        merchant: {
          ...state.merchant,
          merchant: {
            ...state.merchant.merchant,
            selectedSubscriptionsPerCategory: action.payload,
          },
        },
      };

    case DISABLE_MERCHANT_SUBSCRIPTION_LOADING:
      return {
        ...state,
        merchant: {
          ...state.merchant,
          merchant: {
            ...state.merchant.merchant,
            loading: action.payload,
          },
        },
      };

    case GET_ALL_HASHES_SUCCESS:
      return {
        ...state,
        merchant: {
          ...state.merchant,
          merchant: {
            ...state.merchant.merchant,
            hashes: action.payload,
          },
        },
      };

    case GET_HASH_SUCCESS:
      return {
        ...state,
        merchant: {
          ...state.merchant,
          merchant: {
            ...state.merchant.merchant,
            selectedHash: action.payload,
          },
        },
      };

    case CREATE_HASH_SUCCESS:
      return {
        ...state,
        merchant: {
          ...state.merchant,
          merchant: {
            ...state.merchant.merchant,
            createdHash: action.payload,
          },
        },
      };

    // if no case is adequate to the action type, then the previous state is returned with no modifications done
    default:
      return state;
  }
};
