import React, { useEffect, useState, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Button } from '@mui/material';
import { InputField } from 'components/common/InputField';
import { PageTitle } from 'components/common';
import merchantContext from 'context/merchant/MerchantContext';

export const CreateCategory = ({
  categoryData = {},
  onSave = () => {},
  isEditMode = false,
}) => {
  const { categoryName } = useParams();
  const navigate = useNavigate();

  const { state, getMerchantSubscriptions, updateMerchantCategory } =
    useContext(merchantContext);
  const { merchant } = state || {};

  const [data, setData] = useState(categoryData);

  useState(() => {
    getMerchantSubscriptions(); // TODO fix error in console
  }, []);

  useEffect(() => {
    if (categoryName) {
      const allCategories =
        merchant?.merchant?.merchantSubscriptions?.categories || [];

      const category = allCategories.find(
        (category) => category.categoryName === categoryName
      );
      if (category) {
        setData({
          categoryName: category?.categoryName,
          categoryImageURL: category?.categoryImageURL,
        });
      }

      return;
    }
  }, [categoryName, merchant?.merchant]);

  const onSubmit = async () => {
    if (!isEditMode) {
      onSave(data);
    } else {
      await updateMerchantCategory(categoryName, data);
      navigate('/merchant-subscriptions');
    }
  };

  return (
    <>
      <PageTitle title={'Create category'} />
      <Box
        sx={{
          width: '100%',
          maxWidth: '400px',
          display: 'block',
          ml: 'auto',
          mr: 'auto',
        }}
      >
        <InputField
          name="categoryName"
          placeholder="Enter category name"
          value={data?.categoryName || ''}
          fullWidth
          style={{ mb: 1 }}
          onChange={(_, value) => {
            setData({ ...data, categoryName: value });
          }}
        />
        <InputField
          name="categoryImageURL"
          placeholder="Enter image URL"
          value={data?.categoryImageURL || ''}
          fullWidth
          style={{ mb: 1 }}
          onChange={(_, value) => {
            setData({ ...data, categoryImageURL: value });
          }}
        />

        <Button onClick={onSubmit} variant="contained">
          Save
        </Button>
      </Box>
    </>
  );
};
