import { useContext } from 'react';
import { Grid, Box, Button } from '@mui/material';
import EuroIcon from '@mui/icons-material/Euro';
import CurrencyPoundIcon from '@mui/icons-material/CurrencyPound';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { DropdownComponent, FieldsetBox, PageTitle } from 'components/common';
import CoreLayout from 'components/layouts/CoreLayout';
import { InputField } from 'components/common/InputField';
import ThemeContext from 'context/theme/ThemeContext';
import { CURRENCY_LIST } from 'constants/currency-list';

export const CustomerWallet = () => {
  const { withTheme } = useContext(ThemeContext);

  const onChangeCurrencyList = (_, value) => {};

  return (
    <CoreLayout>
      <PageTitle title={'Customer Wallet'} />

      <Grid
        container
        sx={{
          minWidth: '250px',
          width: 'auto',
          height: 'auto',
          border: 'solid 1px #dee2e6',
          borderRadius: '0.3rem',
          display: 'flex',
          alignItems: 'center',
          px: '15px',
        }}
      >
        <Grid item xs={12} sm={6} md={5} sx={{ p: '10px' }}>
          <DropdownComponent
            name="currencyList"
            label="Choose currency"
            defaultValue={CURRENCY_LIST[0]}
            style={{ width: '100%', mb: 2 }}
            options={CURRENCY_LIST}
            onChange={onChangeCurrencyList}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={5} sx={{ p: '10px' }}>
          <InputField placeholder="Amount to add" style={{ mb: 0 }} fullWidth />
        </Grid>
        <Grid item xs={12} sm={12} md={2} sx={{ p: '10px' }}>
          <Button
            variant="contained"
            type="button"
            className={withTheme('btn-secondary')}
            sx={{ width: { xs: '100%' } }}
            onClick={() => {}}
          >
            Add Money
          </Button>
        </Grid>
      </Grid>

      <Grid container spacing={3} sx={{ mt: 2 }}>
        <FieldsetBox
          label={'Remaining amount in Euro'}
          color={'purple'}
          content={'12'}
          height="150px"
          sizes={{ xs: 12, md: 6, lg: 3 }}
        >
          <EuroIcon sx={{ fontSize: '50px' }} />
        </FieldsetBox>
        <FieldsetBox
          label={'Remaining amount in Dollar'}
          color={'purple'}
          content={'23'}
          height="150px"
          sizes={{ xs: 12, md: 6, lg: 3 }}
        >
          <AttachMoneyIcon sx={{ fontSize: '50px' }} />
        </FieldsetBox>
        <FieldsetBox
          label={'Remaining amount in Pound'}
          color={'purple'}
          content={'34.5'}
          height="150px"
          sizes={{ xs: 12, md: 6, lg: 3 }}
        >
          <CurrencyPoundIcon sx={{ fontSize: '50px' }} />
        </FieldsetBox>
      </Grid>
    </CoreLayout>
  );
};
