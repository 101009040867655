// Variables for the Reducer

export const SET_ELEMENT_VALUE = 'MERCHANT_SET_ELEMENT_VALUE';
export const GET_MERCHANT_DASHBOARD_DATA_LOADING =
  'MERCHANT_GET_DASHBOARD_DATA_LOADING';
export const GET_MERCHANT_DASHBOARD_DATA = 'MERCHANT_GET_DASHBOARD_DATA';

export const GET_MERCHANT_SUBSCRIPTIONS_LOADING =
  'GET_MERCHANT_SUBSCRIPTIONS_LOADING';
export const GET_MERCHANT_SUBSCRIPTIONS_SUCCESS =
  'GET_MERCHANT_SUBSCRIPTIONS_SUCCESS';

export const GET_SINGLE_MERCHANT_SUBSCRIPTION_LOADING =
  'GET_SINGLE_MERCHANT_SUBSCRIPTION_LOADING';
export const GET_SINGLE_MERCHANT_SUBSCRIPTION_SUCCESS =
  'GET_SINGLE_MERCHANT_SUBSCRIPTION_SUCCESS';

export const CREATE_MERCHANT_CATEGORY_LOADING =
  'CREATE_MERCHANT_CATEGORY_LOADING';
export const CREATE_MERCHANT_CATEGORY_SUCCESS =
  'CREATE_MERCHANT_CATEGORY_SUCCESS';

export const UPDATE_MERCHANT_CATEGORY_LOADING =
  'CREATE_MERCHANT_CATEGORY_LOADING';
export const UPDATE_MERCHANT_CATEGORY_SUCCESS =
  'CREATE_MERCHANT_CATEGORY_SUCCESS';

export const DELETE_MERCHANT_CATEGORY_LOADING =
  'DELETE_MERCHANT_CATEGORY_LOADING';

export const DISABLE_MERCHANT_SUBSCRIPTION_LOADING =
  'DISABLE_MERCHANT_SUBSCRIPTION_LOADING';

export const CREATE_MERCHANT_SUBSCRIPTION_LOADING =
  'CREATE_MERCHANT_SUBSCRIPTION_LOADING';

export const UPDATE_MERCHANT_SUBSCRIPTION_LOADING =
  'UPDATE_MERCHANT_SUBSCRIPTION_LOADING';

export const SET_MERCHANT_SELECTED_SUBSCRIPTIONS =
  'SET_MERCHANT_SELECTED_SUBSCRIPTIONS';

export const GET_HASHES_LOADING = 'GET_HASHES_LOADING';
export const GET_ALL_HASHES_SUCCESS = 'GET_ALL_HASHES_SUCCESS';
export const GET_HASH_LOADING = 'GET_HASH_LOADING';
export const GET_HASH_SUCCESS = 'GET_HASH_SUCCESS';
export const CREATE_HASH_LOADING = 'CREATE_HASH_LOADING';
export const CREATE_HASH_SUCCESS = 'CREATE_HASH_SUCCESS';
