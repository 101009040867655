import { PageTitle } from 'components/common';
import CoreLayout from 'components/layouts/CoreLayout';
import EuroIcon from '@mui/icons-material/Euro';
import CurrencyPoundIcon from '@mui/icons-material/CurrencyPound';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

export const MerchantWallet = () => {
  return (
    <CoreLayout>
      <PageTitle title={'Merchant Wallet'} />
      <EuroIcon />
      Euro
      <AttachMoneyIcon />
      Dollar
      <CurrencyPoundIcon />
      Pound
    </CoreLayout>
  );
};
