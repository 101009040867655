import axios from 'axios';

const BASE_URL = process.env.REACT_APP_BASE_URL;

/* each method needs to be exported so it can be used in other files
 and also should be using async & await  */

export const getMerchantDashboardData = async () => {
  // returns object containing dashboard data for particular merchant
  const result = await axios.get(`${BASE_URL}/merchant/info`);
  return result.data.message.data;
};

export const getMerchantSubscriptions = async (statusOfSubscription = null) => {
  // GET all of the categories and subscriptions that a merhant has available
  // *PATH Parameter*
  //`empty`: Return ACTIVE and INACTIVE Categories and (nested) subscriptions
  // `?active=true`: Return ACTIVE only Categories and (nested) subscriptions
  // `?active=false`: Return INACTIVE only Categories and (nested) subscriptions
  let customUrl = `${BASE_URL}/merchant/all`;
  if (statusOfSubscription !== null) {
    customUrl = customUrl.concat(`?active=${statusOfSubscription}`);
  }
  const result = await axios.get(`${customUrl}`);
  return result.data.message.data;
};

export const getOneMerchantSubscription = async (
  subscriptioName,
  categoryName
) => {
  const result = await axios.get(
    `${BASE_URL}/merchant/single?subscriptionName=${subscriptioName}&categoryName=${categoryName}`
  );

  return result;
};

export const disableMerchantSubscription = async (
  categoryName,
  subscriptionName
) => {
  await axios.delete(
    `${BASE_URL}/merchant/disableSubscription?categoryName=${categoryName}&subscriptionName=${subscriptionName}`
  );
};

export const createCategory = async (categoryName, data) => {
  await axios.post(
    `${BASE_URL}/merchant/createCategory?categoryName=${categoryName}`,
    { ...data, categoryName }
  );
};

export const deleteCategory = async (categoryName) => {
  await axios.delete(
    `${BASE_URL}/merchant/deleteCategory?categoryName=${categoryName}`
  );
};

export const updateCategory = async (categoryName, data) => {
  const result = await axios.put(
    `${BASE_URL}/merchant/updateCategory?categoryName=${categoryName}`,
    data
  );

  return result.data.message.data;
};

export const createSubscription = async (
  categoryName,
  subscriptionName,
  data
) => {
  const result = await axios.post(
    `${BASE_URL}/merchant/createSubscription?categoryName=${categoryName}&subscriptionName=${subscriptionName}`,
    data
  );
  return result.data.message.data;
};

export const updateSubscription = async (
  categoryName,
  subscriptionName,
  data
) => {
  const result = await axios.put(
    `${BASE_URL}/merchant/updateSubscription?categoryName=${categoryName}&subscriptionName=${subscriptionName}`,
    data
  );
  return result.data.message.data;
};

export const createHash = async (data) => {
  const result = await axios.post(`${BASE_URL}/merchant/createHash`, data);
  return result.data.message;
};

export const getHash = async (id) => {
  const result = await axios.get(`${BASE_URL}/merchant/getHash?id=${id}`);
  return result.data.message;
};

export const deleteHash = async (id) => {
  const result = await axios.delete(`${BASE_URL}/merchant/deleteHash?id=${id}`);
  return result.data.message;
};

export const getAllHashes = async () => {
  const result = await axios.get(`${BASE_URL}/merchant/getHashes`);
  return result.data.message;
};
