import React, { useContext, useState, useEffect, useMemo } from 'react';
import { Grid, Box } from '@mui/material';
import CoreLayout from '../layouts/CoreLayout';
import MerchantContext from 'context/merchant/MerchantContext';
import {
  PageTitle,
  SubscriptionPackagesGrid,
  Sidebar,
} from 'components/common';

export const MerchantArchived = () => {
  const { state, getMerchantSubscriptions } = useContext(MerchantContext);
  const [subscriptionsForListing, setSubscriptionsForListing] = useState([]);
  const [openedSubscriptionId, setOpenedSubscriptionId] = useState(null);
  const { merchant } = state || {};

  useEffect(() => {
    getMerchantSubscriptions(false);
  }, []);

  const merchantName = useMemo(() => {
    return merchant?.merchant?.merchantSubscriptions?.merchantName;
  }, [merchant?.merchant?.merchantSubscriptions?.merchantName]);

  const merchantCategories = useMemo(() => {
    return merchant?.merchant?.merchantSubscriptions?.categories;
  }, [merchant?.merchant?.merchantSubscriptions?.categories]);

  const isLoading = useMemo(() => {
    return merchant?.merchant?.loading || false;
  }, [merchant]);

  const pageTitle = useMemo(() => {
    return `Archived subscriptions ${
      !isLoading && merchantName ? 'for ' + merchantName : ''
    }`;
  }, [merchantName, isLoading]);

  const subscriptionsForSidebar = useMemo(() => {
    let finalData = [];

    merchantCategories?.forEach((category) => {
      // if the category does not have any subscriptions do not display anything in sidebar for that category
      if (!category?.subscriptions?.length) return;

      category?.subscriptions?.forEach((subs) => {
        // add item in the sidebar with data for every archived subscription
        finalData.push({
          text: `${subs?.subscriptionName} (${category?.categoryName})`,
          id: subs['_id'],
          subsData: { ...subs, categoryName: category?.categoryName },
        });
      });
    });

    // return the data that needs to be rendered in the sidebar
    return finalData;

    // the subscriptionsForSidebar value will be reevaluated every time when merchant changes
  }, [merchantCategories]);

  const getSelectedSubscriptionById = (subsId) => {
    // returns category data with list of subscriptions
    // that has only the one subscription that has same id as the one sent as argument to this function

    const updatedCategory = [];
    merchantCategories.forEach((category) => {
      // go through all categories and find the index of the subscription
      // that has same id as the one sent as argument to this function
      const indexOfSubscriptionInCategory = category.subscriptions.findIndex(
        (subs) => subs['_id'] === subsId
      );
      if (indexOfSubscriptionInCategory !== -1) {
        // this category contains the selected question
        // update category data and its subscriptions list
        const tmp = {
          ...category,
          subscriptions: [
            category.subscriptions[indexOfSubscriptionInCategory],
          ],
        };

        updatedCategory.push(tmp);
      }
    });
    // response is an array which has all data for the category as first element
    // so it can be rendered properly in the grid
    return updatedCategory;
  };

  // handles selecting item in the sidebar
  const handleChosingSubscription = (id) => {
    setOpenedSubscriptionId(id);

    if (!id) {
      // if the id is not valid render all categories with subscriptions (render initial data)
      setSubscriptionsForListing([]);
      return;
    }

    // if the id is valid then get chosen subscription data
    // update list of subscriptions that needs to be shown
    // and the section displaying the subscriptions will be rerendered
    const chosenSubscriptionData = getSelectedSubscriptionById(id);
    setSubscriptionsForListing(chosenSubscriptionData);
  };

  // This function is called when a item is selected from the array of items
  const handleItemSelected = (item) => {
    const itemId = item.id || item['_id'];
    if (itemId === openedSubscriptionId) {
      // if the user clicks on already selected item, then the item is unselected
      setOpenedSubscriptionId(null);
      handleChosingSubscription(null);
      return;
    }
    // set the selected item id in state
    setOpenedSubscriptionId(itemId);
    handleChosingSubscription(itemId);
    // on select of item propagate to parent the id of the item
  };

  return (
    <CoreLayout>
      <PageTitle title={pageTitle} />
      {isLoading ? (
        <p>Loading...</p>
      ) : subscriptionsForSidebar?.length ? (
        <Box sx={{ width: '100%', display: 'flex' }}>
          <Sidebar
            data={subscriptionsForSidebar}
            filterPlaceholder="Filter subscriptions and categories"
            onSelectItem={handleChosingSubscription}
            handleItemSelected={handleItemSelected}
            openedSubscriptionId={openedSubscriptionId}
          />
          <Grid container sx={{ width: 'calc(100vw - 360px)' }}>
            <Grid item xs={12} sx={{ pl: '30px' }}>
              {subscriptionsForListing?.length
                ? subscriptionsForListing.map((subscriptionsCategory, i) => (
                    <SubscriptionPackagesGrid
                      key={i}
                      category={subscriptionsCategory}
                      isMerchant={true}
                      onClick={() => {}}
                    />
                  ))
                : null}
            </Grid>
          </Grid>
        </Box>
      ) : (
        <p>There are no archived subscriptions.</p>
      )}
    </CoreLayout>
  );
};
