import React from 'react';
import { Grid, Box, IconButton } from '@mui/material';

import { CustomCard } from 'components/common';

export const SubscriptionPackagesGrid = ({
  category,
  isMerchant = false,
  categoryActionIconComponent = null,
  onCategoryActionClick = () => {},
  onClick = () => {},
  onEditClick = null,
}) => {
  return (
    <>
      <h2
        style={{
          color: category.isActiveCategory ? 'green' : 'blue',
        }}
      >
        {/* // TODO add categoryImageURL */}
        {category.categoryName}

        {categoryActionIconComponent ? (
          <IconButton onClick={() => onCategoryActionClick()}>
            {categoryActionIconComponent}
          </IconButton>
        ) : null}
      </h2>
      <Box sx={{ width: '100%' }}>
        <Grid container spacing={2}>
          {category?.subscriptions?.map((subscription) => {
            return (
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={6}
                key={subscription.subscriptionName}
              >
                <CustomCard
                  sx={{ height: '520px', width: '100%' }}
                  element={subscription}
                  title={subscription.subscriptionName}
                  subHeader={`v.${subscription.subscriptionVersion}`}
                  isButtonDisabled={
                    isMerchant && !subscription.isActiveSubscription
                  }
                  buttonLabel={
                    isMerchant
                      ? !subscription.isActiveSubscription
                        ? 'Disabled'
                        : 'Disable'
                      : subscription.isActiveSubscription
                      ? 'Unsubscribe'
                      : 'Purchase'
                  }
                  {...(onEditClick && {
                    onEditClick: () =>
                      onEditClick(
                        category.categoryName,
                        subscription.subscriptionName
                      ),
                  })}
                  onButtonClick={() => {
                    console.log('Unsubscribed ', category.categoryName);
                    onClick(category.categoryName, subscription);
                  }}
                >
                  <p>
                    <b>{subscription?.subscriptionMarketingTitle}</b>
                  </p>
                  <ul>
                    {subscription.subscriptionMarketingLines.map(
                      (marketingLine, i) => {
                        return <li key={i}>{marketingLine}</li>;
                      }
                    )}
                  </ul>

                  <p style={{ marginTop: '30px' }}>
                    <b>Cost: </b>
                    {subscription?.subscriptionCost}
                    &#x20AC;{' '}
                    {subscription.subscriptionRecurrence
                      ? 'per month'
                      : '(one time purchase)'}
                  </p>
                  <p>
                    <b>Auto renewable: </b>
                    {subscription?.isSubscriptionAutoRenewable ? 'yes' : 'no'}
                  </p>
                  {isMerchant && (
                    <p>
                      <b>Penalty: </b>
                      {subscription?.subscriptionPenalty}&#x20AC;{' '}
                    </p>
                  )}
                </CustomCard>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </>
  );
};
