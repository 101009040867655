import React, { useEffect, useState } from 'react';
import { Tabs, Tab, Box } from '@mui/material';

export const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

const CustomTab = () => null;
const CustomTabPanel = () => null;

const CustomTabs = ({ activeTab, tabsAndPanels, onTabClick = () => {} }) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    onTabClick(event?.target?.id);
  };

  useEffect(() => {
    if (activeTab) {
      const selectedTabIndex = tabsAndPanels?.tabs
        .map((tab) => tab.props.label)
        .indexOf(activeTab);

      if (selectedTabIndex > -1) setValue(selectedTabIndex);
    }
  }, [activeTab]);

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange}>
          {tabsAndPanels?.tabs?.map((tab, index) => (
            <Tab
              label={tab.props.label}
              id={tab.props.id}
              aria-controls={tab.props['aria-controls']}
              key={index}
            />
          ))}
        </Tabs>
      </Box>
      {tabsAndPanels?.tabPanels?.map((element, index) => (
        <TabPanel value={value} index={index} key={index}>
          {element.props.children}
        </TabPanel>
      ))}
    </Box>
  );
};

CustomTabs.CustomTab = CustomTab;
CustomTabs.CustomTabPanel = CustomTabPanel;

export default CustomTabs;
