// Fetching data from API (from backend)
import axios from 'axios';
import { usageReports } from './customersData';

const BASE_URL = process.env.REACT_APP_BASE_URL;

/* each method needs to be exported so it can be used in other files
 and also should be using async & await  */

export const getAllSubscriptionsForCustomer = async () => {
  // GET all of the subscriptions available for and purchased by the customer (logged in user)
  const result = await axios.get(`${BASE_URL}/customer/all`);
  return result.data?.message;
};

export const getSubscriptionsForCustomerFromSingleMerchant = async (
  merchantMasterEmail
) => {
  // GET all of the subscriptions available for one merchant which are purchased by the customer (logged in user)
  const result = await axios.get(
    `${BASE_URL}/customer/single?merchantMasterEmail=${merchantMasterEmail}`
  );
  return result.data?.message;
};

export const getCustomerUsageReports = async () => {
  // returns array containing the most recent transactions
  return await usageReports;
};

export const addSubscription = async (subscriptionName, data) => {
  const result = await axios.put(
    `${BASE_URL}/customer/addSubscription?subscriptionName=${subscriptionName}`,
    data
  );

  return result?.data;
};

export const listMerchants = async () => {
  const result = await axios.get(`${BASE_URL}/customer/listMerchants`);
  return result?.data?.message;
};

export const removeSubscription = async (subscriptionName, data) => {
  await axios.put(
    `${BASE_URL}/customer/removeSubscription?subscriptionName=${subscriptionName}`,
    data
  );
};

export const getHash = async (id) => {
  const result = await axios.get(`${BASE_URL}/customer/getHash?id=${id}`);
  return result.data.message;
};
