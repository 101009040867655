import { useReducer } from 'react';
import {
  getCustomerUsageReports,
  getAllSubscriptionsForCustomer,
  getSubscriptionsForCustomerFromSingleMerchant,
  removeSubscription,
  listMerchants,
} from '../../api/customer';
import CustomerContext from './CustomerContext';
import customerReducer from './CustomerReducer';
import {
  SET_ELEMENT_VALUE,
  GET_CUSTOMER_SUBSCRIPTIONS_LOADING,
  LIST_CUSTOMER_SUBSCRIPTIONS,
  LIST_CUSTOMER_USAGE_DATA,
  GET_CUSTOMER_USAGE_DATA_LOADING,
  GET_CUSTOMER_SUBSCRIPTIONS_FOR_MERCHANT_LOADING,
  GET_CUSTOMER_SUBSCRIPTIONS_FOR_MERCHANT,
  GET_MERCHANTS_FOR_CUSTOMER_LOADING,
  GET_MERCHANTS_FOR_CUSTOMER,
  REMOVE_CUSTOMER_SUBSCRIPTION_LOADING,
} from './types';

export const initialState = {
  customer: {
    customer: {
      customerMerchants: [],
      customerSubscriptions: [],
      customerSubscriptionsForMerchant: [],
      allMerchants: [],
      selectedMerchant: {},
      selectedHash: '',
    },
  },
};

const CustomerState = (props) => {
  // state allows us to access anything in the state and dispatch allows dispatching objects to the reducer
  // populate the customerReducer with the initial state to instantiate it
  const [state, dispatch] = useReducer(customerReducer, initialState);

  const setSubscriptionsOfCustomer = (subscriptions) => {
    // dispatch action to the reducer and update the state accordingly
    dispatch({
      type: LIST_CUSTOMER_SUBSCRIPTIONS,
      payload: subscriptions,
    });
  };

  const setCustomerUsageData = (usageData) => {
    // dispatch action to the reducer and update the state accordingly
    dispatch({
      type: LIST_CUSTOMER_USAGE_DATA,
      payload: usageData,
    });
  };

  const listCustomerSubscriptions = () => {
    // dispatch action to the reducer and update the state accordingly
    dispatch({
      type: GET_CUSTOMER_SUBSCRIPTIONS_LOADING,
      payload: true,
    });

    // get all consumer's subscriptions that can be purchased or already are
    getAllSubscriptionsForCustomer()
      .then((result) => {
        setSubscriptionsOfCustomer(result);
        dispatch({
          type: GET_CUSTOMER_SUBSCRIPTIONS_LOADING,
          payload: false,
        });
      })
      .catch((error) => console.log(error));
  };

  const removeCustomerSubscription = (subscriptionName, data) => {
    // dispatch action to the reducer and update the state accordingly
    dispatch({
      type: REMOVE_CUSTOMER_SUBSCRIPTION_LOADING,
      payload: true,
    });

    // remove customer subscription
    removeSubscription(subscriptionName, data)
      .then(() => {
        listCustomerSubscriptions();
        dispatch({
          type: REMOVE_CUSTOMER_SUBSCRIPTION_LOADING,
          payload: false,
        });
      })
      .catch((error) => console.log(error));
  };

  const listCustomerSubscriptionsForMerchant = (merchantMasterEmail) => {
    // dispatch action to the reducer and update the state accordingly
    dispatch({
      type: GET_CUSTOMER_SUBSCRIPTIONS_FOR_MERCHANT_LOADING,
      payload: true,
    });

    // get all consumer's subscriptions that are already purchased for only one merchant
    getSubscriptionsForCustomerFromSingleMerchant(merchantMasterEmail)
      .then((result) => {
        dispatch({
          type: GET_CUSTOMER_SUBSCRIPTIONS_FOR_MERCHANT,
          payload: result,
        });
        dispatch({
          type: GET_CUSTOMER_SUBSCRIPTIONS_FOR_MERCHANT_LOADING,
          payload: false,
        });
      })
      .catch((error) => console.log(error));
  };

  const listMerchantsForCustomer = () => {
    // dispatch action to the reducer and update  the state accordingly
    dispatch({
      type: GET_MERCHANTS_FOR_CUSTOMER_LOADING,
      payload: true,
    });

    listMerchants().then((result) => {
      dispatch({
        type: GET_MERCHANTS_FOR_CUSTOMER,
        payload: result,
      });
      dispatch({
        type: GET_MERCHANTS_FOR_CUSTOMER_LOADING,
        payload: false,
      });
    });
  };

  const listCustomersUsageReports = () => {
    // dispatch action to the reducer and update the state accordingly
    dispatch({
      type: GET_CUSTOMER_USAGE_DATA_LOADING,
      payload: true,
    });

    // get consumer's usage data
    getCustomerUsageReports()
      .then((result) => setCustomerUsageData(result))
      .catch((err) => console.log(err));
  };

  const setElementValue = (name, value) => {
    // dispatch action to the reducer and update the state accordingly
    dispatch({
      type: SET_ELEMENT_VALUE,
      payload: {
        name,
        value,
      },
    });
  };

  return (
    <CustomerContext.Provider
      value={{
        state: state || {},
        setElementValue,
        listCustomersUsageReports,
        listCustomerSubscriptions,
        listCustomerSubscriptionsForMerchant,
        listMerchantsForCustomer,
        removeCustomerSubscription,
      }}
    >
      {props.children}
    </CustomerContext.Provider>
  );
};

export default CustomerState;
