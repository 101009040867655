import React, { useEffect, useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';

export const DropdownComponent = ({
  onChange,
  defaultValue = null,
  options,
  label,
  name,
  style = {},
  isOptionEqualToValue = null,
  size = 'small',
  disabled = false,
  ...rest
}) => {
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    if (defaultValue && defaultValue?.key && defaultValue?.value) {
      setValue(defaultValue);
    }
  }, [defaultValue]);

  return (
    <>
      <Autocomplete
        name={name}
        options={options}
        value={value}
        sx={{ width: 300, mt: 2, ...style }}
        autoHighlight
        autoComplete
        disabled={disabled}
        getOptionLabel={(option) => option.value}
        {...(isOptionEqualToValue && {
          isOptionEqualToValue: isOptionEqualToValue,
        })}
        onChange={(_, newValue) => {
          setValue(newValue);
          onChange(name, newValue);
        }}
        renderInput={(params) => (
          <TextField {...params} size={size} name={name} label={label} />
        )}
        {...{ rest }}
      />
    </>
  );
};
