import React, { useState, useContext, useMemo, useEffect } from 'react';
import { Box, Button, Grid } from '@mui/material';
import { ContentCopy } from '@mui/icons-material';
import CoreLayout from 'components/layouts/CoreLayout';
import MerchantContext from 'context/merchant/MerchantContext';
import ToastMessageContext from 'context/toastMessage/ToastMessageContext';
import { PageTitle, SimpleAccordion } from 'components/common';
import { deleteHash } from 'api/merchant';

export const GenerateLink = ({}) => {
  const {
    state,
    loadSelectedSubscriptionsPerCategory,
    listMerchantDashboardData,
    createHash,
  } = useContext(MerchantContext);
  const { showToastMessage } = useContext(ToastMessageContext);

  const { merchant } = state || {};
  const [showRawData, setShowRawData] = useState(false);
  const [generatedLink, setGeneratedLink] = useState('');

  useEffect(() => {
    loadSelectedSubscriptionsPerCategory();
    listMerchantDashboardData();
  }, []);

  const selectedSubscriptions = useMemo(() => {
    return merchant?.merchant?.selectedSubscriptionsPerCategory;
  }, [merchant?.merchant?.selectedSubscriptionsPerCategory]);

  const newHash = useMemo(() => {
    return merchant?.merchant?.createdHash;
  });

  const prepareMerchantData = (subscriptions) => {
    const groupedByCategory = Object.keys(subscriptions).reduce((acc, item) => {
      if (!acc || !Array.isArray(acc)) {
        acc = [];
      }
      const subs = subscriptions[item].map((s) => {
        return s?.subsData?.['_id'];
      });
      acc.push({
        id: subscriptions[item][0].subsData.categoryId,
        subscriptions: subs,
      });
      return acc;
    }, []);

    return {
      categories: groupedByCategory,
    };
  };

  const handleLinkGeneration = async () => {
    const data = prepareMerchantData(selectedSubscriptions);
    const tmpData = {
      merchantId: 'merchantId',
      data: JSON.stringify(data),
      digitalProductUrl: 'https://www.story4cv.com',
      purchaseConfirmationUrl:
        'http://www.IAmTheMercnaht.com/newPurchase?token=2873612&customerToken=exampleToken&productId=xyz&subscriptionName=gold',
      affiliateId: '128184632974',
      couponId: '2713281',
      merchantEmail: 'i.am.merchant@merchant.com',
      money: 10.99,
      currency: 'EUR',
    };
    // TODO;
    // const re = deleteHash('658309b50bc073553889b5c2');
    // console.log('deleteHash', re);
    const res = await createHash(data);
    console.log('res', res);

    let link = `https://api4billing.com/chooseSubscription?`;

    Object.entries(tmpData)?.forEach(([key, value], index) => {
      link = link.concat(
        `${index !== 0 ? '&' : ''}${key}=${encodeURIComponent(value)}`
      );
    });

    setGeneratedLink(link);
  };

  return (
    <CoreLayout>
      <PageTitle title={'Fill in link data'} />
      <Grid
        container
        spacing={4}
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <Grid item xs={7} sx={{ marginBottom: '40px' }}>
          <SimpleAccordion data={selectedSubscriptions} />
        </Grid>
        <Grid
          item
          xs={5}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            height: '100%',
            mb: '40px',
          }}
        >
          <Box sx={{ mt: 3 }}>
            <Button
              onClick={handleLinkGeneration}
              disabled={!!generatedLink}
              variant="contained"
            >
              Generate link
            </Button>
            <Button
              endIcon={<ContentCopy />}
              disabled={!generatedLink}
              variant="contained"
              onClick={() => {
                navigator.clipboard.writeText(generatedLink);
                showToastMessage({
                  message: 'Link copied successfully!',
                  severity: 'success',
                });
              }}
              sx={{ ml: 3 }}
            >
              Copy Link
            </Button>
            <Button
              variant="text"
              sx={{ ml: 3 }}
              onClick={() => setShowRawData(!showRawData)}
            >
              {!showRawData ? 'Show' : 'Hide'} raw data
            </Button>
          </Box>

          {generatedLink ? (
            <Box
              sx={{
                inlineSize: 'calc(100% - 40px)',
                overflowWrap: 'break-word',
              }}
            >
              {generatedLink}
            </Box>
          ) : null}

          {showRawData ? (
            <pre>{JSON.stringify(selectedSubscriptions, null, 2)}</pre>
          ) : null}
        </Grid>
      </Grid>
    </CoreLayout>
  );
};
