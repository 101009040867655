import React, { useContext } from 'react';
import ThemeContext from '../../context/theme/ThemeContext';
import {
  Typography,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Button,
  Divider,
  IconButton,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

/* This is a custom component for vertical Cards */
/* It uses styles from the active theme from the Theme Context */
/* Accepts custom styles in props */

export const CustomCard = (props) => {
  const themeContext = useContext(ThemeContext);
  const { withTheme } = themeContext;

  return (
    <Card
      sx={{
        maxWidth: 345,
        marginBottom: 5,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: '10px 10px 10px 10px',
        ...props.sx,
      }}
    >
      <CardHeader
        title={props.title}
        {...(props.onEditClick && {
          action: (
            <IconButton aria-label="edit" onClick={props.onEditClick}>
              <EditIcon />
            </IconButton>
          ),
        })}
        subheader={props.subHeader}
        sx={{ height: '60px', alignItems: 'flex-start' }}
      />

      <Divider light />
      <CardContent sx={{ height: 350 }}>
        <Typography variant="body2" color="text.secondary">
          {props.content}
        </Typography>
        {/* If there are some child components (or data) nested between the <Card> and </Card> tags, 
          when this component is called, then that component/s (or data) are rendered as a content here */}
        {props.children}
      </CardContent>

      {/* If no button label is sent as a prop to the component - then the Card Actions section (Button) is not shown */}
      {props.buttonLabel && (
        <>
          <Divider light />
          <CardActions
            disableSpacing
            sx={{ justifyContent: 'center', height: '55px' }}
          >
            <Button
              onClick={props.onButtonClick}
              disabled={props.isButtonDisabled || false}
              className={`${withTheme('card-button')} ${withTheme(
                'btn-secondary'
              )}`}
              variant="contained"
            >
              {props.buttonLabel}
            </Button>
          </CardActions>
        </>
      )}
    </Card>
  );
};
