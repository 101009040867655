import React, { useContext, useEffect } from 'react';
import { Grid, Snackbar, Alert } from '@mui/material';
import ThemeContext from '../../context/theme/ThemeContext';
import ToastMessageContext from 'context/toastMessage/ToastMessageContext';
import { Alert as CustomAlert } from 'components/common';
import MainNavBar from './MainNavBar';

/* This is a component that needs to be used as a wrapper of pages that we want  
  to have NavBar on the page and container layout */

const CoreLayout = ({ children }) => {
  const { theme, closeAlert, withTheme } = useContext(ThemeContext);
  const { state: toastMessageData, closeToastMessage } =
    useContext(ToastMessageContext);
  const { toastMessage } = toastMessageData;
  const { shouldShowAlert, alertMessage, alertDuration } = theme || {};

  useEffect(() => {
    if (shouldShowAlert && alertDuration) {
      const timer = setTimeout(() => {
        closeAlert();
      }, alertDuration);
      return () => clearTimeout(timer);
    }
  }, [shouldShowAlert]);

  return (
    <Grid item>
      <MainNavBar />
      {shouldShowAlert && (
        <CustomAlert
          message={alertMessage}
          isOpen={shouldShowAlert}
          onClose={closeAlert}
        />
      )}
      <Grid item sx={{ width: '100%' }} className={withTheme('page-wrapper')}>
        <Grid container rowSpacing={1} direction="column" className="container">
          {children}
        </Grid>
      </Grid>

      <Snackbar
        open={!!toastMessage?.message}
        autoHideDuration={6000}
        onClose={closeToastMessage}
        severity={toastMessage?.severity}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        key={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert onClose={closeToastMessage} sx={{ width: '100%' }}>
          {toastMessage?.message}
        </Alert>
      </Snackbar>
    </Grid>
  );
};

export default CoreLayout;
