// Redux for moving attributes around with state
import {
  SET_ELEMENT_VALUE,
  LIST_CUSTOMER_SUBSCRIPTIONS,
  LIST_CUSTOMER_USAGE_DATA,
  GET_CUSTOMER_SUBSCRIPTIONS_FOR_MERCHANT,
  GET_MERCHANTS_FOR_CUSTOMER,
} from './types';

/* 
  This is the Cutomer's Context reducer. It accepts action type (string) 
  and updates the store's state accordingly
*/

export default (state, action) => {
  const data = action.payload;

  switch (action.type) {
    // map all action types to corresponding action (change of state)

    case SET_ELEMENT_VALUE:
      const { name, value } = data;
      return {
        ...state,
        customer: {
          ...state.customer,
          [name]: value,
        },
      };

    case LIST_CUSTOMER_SUBSCRIPTIONS:
      return {
        ...state,
        customer: {
          ...state.customer,
          customer: {
            ...state.customer.customer,
            customerMerchants: data, //TODO improve
            customerSubscriptions: data,
          },
        },
      };

    case GET_CUSTOMER_SUBSCRIPTIONS_FOR_MERCHANT:
      return {
        ...state,
        customer: {
          ...state.customer,
          customer: {
            ...state.customer.customer,
            customerSubscriptionsForMerchant: data,
          },
        },
      };

    case LIST_CUSTOMER_USAGE_DATA:
      return {
        ...state,
        customer: {
          ...state.customer,
          customer: {
            ...state.customer.customer,
            customerUsageData: data,
          },
        },
      };

    case GET_MERCHANTS_FOR_CUSTOMER:
      return {
        ...state,
        customer: {
          ...state.customer,
          customer: {
            ...state.customer.customer,
            allMerchants: data,
          },
        },
      };

    // if no case is adequate to the action type, then the previous state is returned with no modifications done
    default:
      return state;
  }
};
